<script setup>
import { Modal, Input, Button, Checkbox, Textarea } from '@/components/index'
import CalendarServices from '../../../utils/services/calendarServices'
import Datepicker from '@vuepic/vue-datepicker'
import moment from 'moment/moment'
import { store } from '@/store'
import router from '@/router'
import { defineProps, ref, defineEmits, onMounted } from 'vue'

const props = defineProps(['event', 'friends'])
defineEmits(['closeModal'])

const errors = ref([])
const fixedFriends = ref([])
const form = ref({
  title: props.event.event.title,
  description: props.event.event.description ?? '',
  start_date: new Date(props.event.event.start_date),
  end_date: new Date(props.event.event.end_date)
})
const service = new CalendarServices()

const submit = () => {
  errors.value = []
  if (form.value.description.length > 144) {
    errors.value.description = 'Description is too long'
  }

  if (form.value.start_date === null) {
    errors.value.start_date = 'Start date is required'
  }

  if (form.value.title === '') {
    errors.value.title = 'Title is required'
  }

  if (errors.value.description || errors.value.title || errors.value.start_date) {
    return
  }

  const data = {
    title: form.value.title,
    description: form.value.description,
    start_date: moment(form.value.start_date).format('YYYY-M-D'),
    end_date: form.value.end_date ? moment(form.value.end_date).format('YYYY-M-D') : null,
    friends: fixedFriends.value
  }
  service.editEvent(props.event.event.id, store.user.id, data)
    .then(() => {
      router.go()
    })
}

onMounted(() => {
  fixedFriends.value = []

  const getSelected = (value) => {
    let selected = false
    props.event.event.users.forEach(x => {
      if (x.id === value) {
        selected = true
      }
    })
    return selected
  }

  props.friends.forEach(x => {
    fixedFriends.value.push(
      {
        id: x.id,
        name: x.first_name + ' ' + x.last_name,
        selected: getSelected(x.id)
      }
    )
  })
})
</script>

<template>
  <Modal
      :title="'Edit ' + event.name + ' Event'"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div class="element-section">
        Title of event <span class="text-danger">{{ errors['title'] }}</span>
        <Input v-model="form.title" />
      </div>
      <div class="element-section">
        Description of event <span class="text-danger">{{ errors['description'] }}</span>
        <Textarea v-model="form.description" :length="144" />
      </div>

      <div class="element-section">
        Select start date <span class="text-danger">{{ errors['start_date'] }}</span>
        <Datepicker
            v-model="form.start_date"
            format="dd/MM/yyyy"
            multiCalendars
            :enableTimePicker="false"
            placeholder="Start date"
            showNowButton
        />
      </div>
      <div class="element-section" v-if="form.start_date">
        Select end date (optional)
        <Datepicker
            v-model="form.end_date"
            format="dd/MM/yyyy"
            multiCalendars
            :enableTimePicker="false"
            placeholder="End date"
            :minDate="new Date(form.start_date)"
            showNowButton
        />
      </div>

      <div class="element-section">
        Invite Friends
        <div class="grid">
          <div v-for="(friend, index) in fixedFriends" :key="friend.id">
            <Checkbox :fieldId="friend.name" v-model:checked="fixedFriends[index]['selected']" :label="friend.name" />
          </div>
        </div>
      </div>

      <hr/>
      <Button action="success" @click="submit">Submit Changes</Button>
    </template>
  </Modal>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  max-height: 160px;
  overflow-y: scroll;
}
</style>
