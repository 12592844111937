<script setup>
import { Button } from '@/components/index'
import FeedbackServices from '@/utils/services/feedbackServices'
import Editor from '@tinymce/tinymce-vue'
import moment from 'moment'
import { store } from '@/store'
import { defineProps, defineEmits, ref } from 'vue'
import Panel from '@/components/PanelComp.vue'

const props = defineProps(['ticket'])
const emit = defineEmits(['updateIssues'])

const message = ref(null)
const user = store.user
const service = new FeedbackServices()
const votes = ref(props.ticket.votes)
const messageError = ref(false)

const sendMessage = () => {
  if (message.value === 'null') {
    messageError.value = true
    return
  }

  const data = {
    message: message.value
  }

  service.sendMessage(props.ticket.id, user.id, data)
    .then((response) => {
    // eslint-disable-next-line vue/no-mutating-props
      props.ticket.messages.push(response.data)
      message.value = null
    })
}

const sendVote = () => {
  service.sendVote(props.ticket.id, user.id)
    .then((response) => {
      votes.value = response.data
    })
}

const resolveIssue = () => {
  service.resolveIssue(props.ticket.id)
    .then(() => {
      emit('updateIssues')
    })
}

const getVoted = (votes) => {
  let voted = false

  if (votes) {
    votes.forEach(x => {
      if (x.user_id === user.id) {
        voted = true
      }
    })
  }

  return voted
}

</script>

<template>
  <Panel>
    <div class="ticket-header">
      <h4 class="uk-margin-remove">{{ ticket.description }}</h4>
      <div>
        <div class="pill default">{{ ticket.section }}</div>
        <div class="pill" :class="ticket.type">{{ ticket.type }}</div>
      </div>
      <div class="uk-flex" v-if="!ticket.deleted_at">
        <div v-if="!getVoted(votes)" uk-tooltip="title:Up vote" @click="sendVote"><i class="fa-solid fa-thumbs-up"></i></div>
        <div v-else uk-tooltip="title:Down vote" @click="sendVote"><i class="fa-solid fa-thumbs-down"></i></div>
        <div>{{ votes.length }}</div>
      </div>
    </div>
    <hr>
    <div v-html="ticket.content"></div>
    <div class="ticket-date">{{ moment(ticket.created_at).format('Do MMMM YYYY') }}</div>
  </Panel>

  <Panel class="ticket-message" :class="message.user_id === user.id ? 'owned' : 'not-owned'" v-for="message in ticket.messages" :key="message.id">
    <div v-html="message.message"></div>
    <div class="ticket-date">{{ moment(message.created_at).format('Do MMMM YYYY') }} - {{ message.user_id === 1 ? 'Admin' : 'User' }}</div>
  </Panel>
  <Panel>
    <editor v-if="!ticket.deleted_at"
        placeholder="Send reply..."
        api-key="h8gmy3cnobj2akxw0sts8xfmpka97an30s7zs5mcbh8jbh4t"
        v-model="message"
        :init="{
                height: 200,
                menubar: false,
                branding: false,
                plugins: [
                  'advlist','autolink',
                  'lists','link','charmap','searchreplace','visualblocks',
                  'fullscreen','insertdatetime','media','table'
                ],
                toolbar:
                  'undo redo | casechange | bold italic underline | bullist numlist'
              }"
    />
    <div class="form-actions" v-if="!ticket.deleted_at">
      <Button dark action="success" @click="sendMessage">
        Send Message
      </Button>
      <Button v-if="(ticket.type === 'support' && ticket.issued_by === user.id) || user.id === 1" dark action="info" @click="resolveIssue">
        Mark as resolved
      </Button>
    </div>
  </Panel>
</template>

<style scoped>
.ticket-header {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
}

.ticket-date {
  font-size: 14px;
  color: #999999;
}

.ticket-message {
  margin-left: 20px;
  padding: 5px 15px;
  border-radius: 10px;
}

.owned {
  border-left: 2px solid var(--primaryColour);
}
.not-owned {
  border-left: 2px solid #999999;
}

.pill {
  display: inline-flex;
  border-radius: 20px;
  margin: 0 5px;
  padding: 0 10px;
  color: white;
  text-transform: capitalize !important;
}

.default {
  background: var(--primaryColour);
}
.fix {
  background-color: var(--dangerColour);
}
.feature {
  background-color: var(--infoColour);
}
.support {
  background-color: var(--successColour);
}

.form-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.fa-thumbs-up {
  color: var(--infoColour);
  margin-right: 5px;
}
.fa-thumbs-down {
  color: var(--dangerColour);
  margin-right: 5px;
}
</style>
