<template>
  <div class="ribbon" :class="[position === 'open' || open === 'open' ? 'open' : 'close']" >
    <div class="icon uk-float-left" @click="$emit('toggleSidebar'); toggleSidebar();">
      <span v-if="position === 'open'"><i class="fa-solid fa-chevron-left"></i></span>
      <span v-if="position === 'close'"><i  class="fa-solid fa-bars"></i></span>
    </div>
    <div class="uk-float-right uk-flex">
      <div class="uk-inline icon">
        <Dropdown>
          <template #display>
            <i class="fa-solid fa-table-cells" style="color: var(--bodyColour)!important;"></i>
          </template>
          <template #content>
            <div class="grid-3" style="width: 150px!important;">
              <div style="margin: auto;" @click="this.calculator = true">
                <i class="fa-solid fa-calculator"></i>
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="icon uk-position-relative" @click="notifySideBar = true">
        <i class="fa-solid fa-bell"></i>
        <!--        <span class="uk-badge uk-position-top-right uk-margin-small-left">2</span>-->
      </div>
    </div>
  </div>

  <Sidebar
      v-if="notifySideBar"
      title="<i class='fa-solid fa-bell'></i> Notifications"
      @closeModal="notifySideBar = false"
  ></Sidebar>

  <calculator
      v-if="calculator === true"
      @closeModal="calculator = false"
  />

</template>

<script>
import Calculator from '@/components/calculator/CalculatorComponent.vue'
import { Sidebar, Dropdown } from '@/components/index'

export default {
  name: 'SystemRibbon',
  components: {
    Dropdown,
    Sidebar,
    Calculator
  },

  props: {
    open: String
  },

  data () {
    return {
      position: 'open',
      notifySideBar: false,
      calculator: false
    }
  },

  methods: {
    toggleSidebar () {
      if (this.position === 'open') {
        this.position = 'close'
      } else {
        this.position = 'open'
      }
    }
  }
}
</script>

<style scoped>
.ribbon {
  position: fixed;
  top: 0;
  height: 50px;
  background-color: var(--primaryColour);
  z-index: 5;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.ribbon.open {
  left: 250px;
  width: calc(100% - 270px);
  transition: 0.1s;
}
.ribbon.close {
  left: 75px;
  width: calc(100% - 95px);
  transition: 0.1s;
}

.uk-offcanvas-bar {
  background-color: var(--tertiaryColour);
}

.uk-card:hover {
  transform: translateX(5px);
}

.icon {
  width: 30px;
  height: 30px;
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--bodyColour);
  transition: .2s;
}
.icon:hover {
  background-color: var(--hoverColour);
  transition: .2s;
  cursor: pointer;
}
</style>
