import { createRouter, createWebHistory } from 'vue-router'
import {
  Login,
  Dashboard,
  Projects,
  Notes,
  Wishlists,
  Calendar,
  Accounts,
  Password,
  Travels,
  Travel,
  Account,
  Feedback,
  Unauthorised,
  NotFound
} from '../../src/pages'

const routes = [
  // Authenticated Layouts
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/feedback', name: 'Feedback', component: Feedback, meta: { requiresAuth: true } },
  { path: '/projects', name: 'Projects', component: Projects, meta: { requiresAuth: true } },
  { path: '/notes', name: 'Notes', component: Notes, meta: { requiresAuth: true } },
  { path: '/wish-lists', name: 'Wishlist', component: Wishlists, meta: { requiresAuth: true } },
  { path: '/calendar', name: 'Calendar', component: Calendar, meta: { requiresAuth: true } },
  { path: '/finance-accounts', name: 'Accounts', component: Accounts, meta: { requiresAuth: true } },
  { path: '/password-manager', name: 'Passwords', component: Password, meta: { requiresAuth: true } },
  { path: '/account', name: 'Account', component: Account, meta: { requiresAuth: true } },
  { path: '/travels', name: 'Travels', component: Travels, meta: { requiresAuth: true } },
  { path: '/travel/:id', name: 'Travel', component: Travel, meta: { requiresAuth: true } },
  { path: '/unauthorised', name: 'Unauthorised', component: Unauthorised, meta: { requiresAuth: true } },
  // Guest Layouts
  { path: '/login', name: 'Login', component: Login },

  // Redirects
  { path: '/', redirect: '/dashboard' },
  // Catch 404
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound, meta: { requiresAuth: true } }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  const isAuthenticated = !!localStorage.getItem('solToken')
  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      return {
        name: 'Login',
        query: { redirect: to.fullPath }
      }
    }
  }
})

export default router
