import axios from 'axios'

const setAuthHeader = () => {
  if (localStorage.getItem('solToken')) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('solToken')
  } else {
    delete axios.defaults.headers.Authorization
  }
}

export default setAuthHeader
