<script setup>
import { Button } from '@/components/index'
import { defineProps } from 'vue'
import moment from 'moment'
import router from '@/router'

defineProps(['currentEvent'])

const dayDifference = (day1, day2) => {
  const start = moment(day1)
  const end = day2.startOf('day')
  const difference = start.diff(end, 'days')
  let amount = ''
  let text = ''

  if (difference === 0) {
    amount = 'Today'
    text = ''
  } else if (difference > 0) {
    amount = difference
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Left'
  } else {
    amount = Math.abs(difference)
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Ago'
  }

  return {
    amount,
    text
  }
}
</script>

<template>
  <div>
    <div class="current-event-info">
      <p class="days-count">
        {{ dayDifference(currentEvent.end_date, moment()).amount }}
      </p>
      <p class="days-text">
        {{ dayDifference(currentEvent.end_date, moment()).text }} <br v-if="dayDifference(currentEvent.end_date, moment()).text" />
        {{ moment(currentEvent.end_date).format('Do MMMM YYYY') }}
      </p>
    </div>
    <div>
      <Button action="info" class="full-width" @click="router.push({ name: 'Wishlist' })">
        View
      </Button>
    </div>
  </div>
</template>

<style scoped>
.days-count {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.days-text {
  text-align: center;
  margin: 0;
}
</style>
