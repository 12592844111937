<template>
  <div class="timeline">
    <div v-for="(date, index) in content" :key="index" class="container">
      <div class="content">
        <h3>01/08/2022</h3>
        <p>09:45 Departure (England - Manchester)</p>
        <p>15:00 Arrival (America - Washington)</p>

        <div class="timeline">
          <div class="container">
            <div class="content">
              <h3>Events</h3>
              <p>Get to the villa</p>
              <p>Buy food</p>
            </div>
          </div>
          <div class="container">
            <div class="last-content" />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <h3>02/08/2022</h3>
        <p></p>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <h3>02/08/2022</h3>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineComp',
  setup () {
    const content = {
      '01/08/2022': [
        '09:45 Departure (England - Manchester)',
        '15:00 Arrival (America - Washington)',
        {
          Events: [
            'Get to Villa',
            'Go to Shop'
          ]
        }
      ],
      '02/08/2022': [
        '12:00 get the RV (Washington)',
        'Stock the RV with food',
        {
          Events: [
            'Get out of villa',
            'Get on the road at 16:00'
          ]
        }
      ]
    }

    return {
      content
    }
  }
}
</script>

<style scoped>
.timeline {
  position: relative;
  max-width: 1200px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--primaryColour);
  top: 0;
  bottom: 0;
  left: 24px;
}

.timeline > .container:last-child {
  transform: translatey(1px);
  z-index: 1;
}

/* Container around content */
.container {
  padding: 0 40px;
  position: relative;
  background-color: inherit;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: var(--bodyColour);
  border: 4px solid var(--primaryColour);
  top: 0;
  left: 10px;
  border-radius: 50%;
  z-index: 1;
}

/* The actual content */
.content {
  padding: 0 30px;
  position: relative;
}
.last-content {
  content: '.';
  padding-bottom: 30px;
  margin-bottom: 20px;
}

</style>
