<script setup>

</script>

<template>
  <div class="guest-layout">
    <div class="auth-plate">
      <div class="sol-logo">
        <img src="../../assets/sol-login-image.png" alt="Sol Logo">
      </div>
      <div class="action-form">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.guest-layout {
  display: flex;
  background-color: var(--secondaryColour);
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.auth-plate {
  user-select: none;
  display: flex;
  background-color: var(--tertiaryColour);
  height: 550px;
  width: 1000px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 14px 36px -3px rgba(0,0,0,0.58);
  -moz-box-shadow: 0px 14px 36px -3px rgba(0,0,0,0.58);
  box-shadow: 0px 14px 36px -3px rgba(0,0,0,0.58);
}

.sol-logo > img {
  -webkit-user-drag: none;
  min-width: 550px;
  min-height: 550px;
}

.action-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  width: 350px;
  padding: 40px 20px;
}

@media only screen and (max-width: 940px) {
  .auth-plate {
    height: 400px;
    width: 700px
  }
  .sol-logo > img {
    min-width: 400px;
    min-height: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .auth-plate {
    height: 550px;
    width: 700px;
  }
  .sol-logo {
    display: none;
  }
}
</style>
