<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Button, Accordion, Input, Modal, Panel, Tabs } from '@/components/index'
import ProjectServices from '@/utils/services/projectServices'
import createSeries from '@/utils/graphWork'
import isBright from '@/utils/isBright'
import Datepicker from '@vuepic/vue-datepicker'
import moment from 'moment'
import { store } from '@/store'
import router from '@/router'
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const props = defineProps(['projectKey'])
defineEmits(['changeVue'])
const user = store.user
const taskCount = ref(0)
const completedCount = ref(0)
const project = ref({})
const series = ref([])
const completedModal = ref(false)
const completedMessage = ref('')
const newTask = ref({
  name: null,
  date: null,
  assignee: null,
  completed: false
})
const newResource = ref({
  quantity: 0,
  name: null,
  units: null,
  collected: false
})
const service = new ProjectServices()

const duration = (start, end) => {
  const a = moment(start)
  const b = moment(end)
  const diff = b.diff(a, 'days')
  return diff + (diff > 1 ? ' days' : ' day')
}

const getCounts = (project) => {
  taskCount.value = 0
  completedCount.value = 0
  taskCount.value = project.tasks.length
  project.tasks.forEach(element => {
    if (element.completed) {
      completedCount.value++
    }
  })
  newTask.value = {
    name: null,
    date: null,
    assignee: null,
    completed: false
  }
  newResource.value = {
    quantity: 0,
    name: null,
    units: null,
    collected: false
  }
}

const getColour = (user) => {
  let colour
  project.value.users.forEach(element => {
    if (element.id === user) {
      colour = element.pivot.colour
    }
  })
  return colour
}

const getTasksProgress = (startDate, endDate) => {
  if (moment().diff(moment(endDate), 'days') > 0) {
    return 'over-deadline'
  } else if (
    moment().isBetween(startDate, endDate) ||
      (moment().format('DD/MM/YYYY') === moment(startDate).format('DD/MM/YYYY') ||
          moment().format('DD/MM/YYYY') === moment(endDate).format('DD/MM/YYYY'))
  ) {
    return 'in-progress'
  } else {
    return ''
  }
}

const getMember = (id) => {
  let name = null
  project.value.users.forEach(x => {
    if (x.id === id) {
      name = x.first_name + ' ' + x.last_name
    }
  })
  return name
}

const taskFunc = (task = null, update = false, remove = false) => {
  const parameters = task === null ? '/' + props.projectKey : '/' + props.projectKey + '/' + task.id
  if (update) {
    newTask.value = {
      name: task.name,
      date: [task.start_date, task.end_date],
      assignee: task.assignee,
      completed: task.completed
    }
  }

  service.task(parameters, {
    delete: remove,
    name: newTask.value.name,
    date: newTask.value.date,
    assignee: newTask.value.assignee,
    completed: newTask.value.completed
  }).then((response) => {
    let check = null
    check = project.value.tasks.find(x => x.id === response.data.id)
    if (!check) {
      project.value.tasks.push(response.data)
    } else if (check && remove) {
      const index = project.value.tasks.findIndex(x => x.id === response.data.id)
      project.value.tasks.splice(index, 1)
    } else {
      const index = project.value.tasks.findIndex(x => x.id === response.data.id)
      project.value.tasks[index] = check
    }
    getCounts(project.value)
  })
}

const resourceFunc = (resource = null, update = false, remove = false) => {
  const parameters = resource === null ? '/' + props.projectKey : '/' + props.projectKey + '/' + resource.id
  if (update) {
    newResource.value = {
      quantity: resource.quantity,
      name: resource.name,
      units: resource.unit,
      collected: resource.collected
    }
  }
  service.resource(parameters, {
    delete: remove,
    name: newResource.value.name,
    quantity: newResource.value.quantity,
    units: newResource.value.units,
    collected: newResource.value.collected
  }).then((response) => {
    let check = null
    check = project.value.resources.find(x => x.id === response.data.id)
    if (!check) {
      project.value.resources.push(response.data)
    } else if (check && remove) {
      const index = project.value.resources.findIndex(x => x.id === response.data.id)
      project.value.resources.splice(index, 1)
    } else {
      const index = project.value.resources.findIndex(x => x.id === response.data.id)
      project.value.resources[index] = check
    }
    getCounts(project.value)
  })
}

const updateMember = (e) => {
  service.updateMember(props.projectKey, e.user_id, {
    colour: e.colour
  }).then((response) => {
    console.log(response)
  }).catch((error) => {
    console.log(error)
  })
}

const confirmModal = (text) => {
  completedMessage.value = text
  let count = 0
  project.value.tasks.forEach(x => {
    if (x.completed === true) {
      count++
    }
  })
  if (count !== project.value.tasks.length) {
    completedMessage.value = 'Some tasks have not been completed. <br> Are you sure you want to proceed?'
  }
  completedModal.value = true
}

const completed = () => {
  service.projectCompleted(props.projectKey)
    .then(() => {
      router.go()
    })
}

onMounted(() => {
  service.getProject(props.projectKey)
    .then((response) => {
      project.value = response.data
      series.value = createSeries(project.value)
      getCounts(project.value)
    })
})
</script>

<template>
  <Authenticated>
    <template #header>
      <Button action="info" @click="$emit('changeVue')">
        <i class="fa-solid fa-arrow-left"></i> Back
      </Button>
      <span>{{ project.title }}</span>
      <div>
        <Button v-if="user.id === project.user_id" action="success" @click="confirmModal('Is the project complete?')">
          <i class="fas fa-check"></i> Completed
        </Button>
      </div>
    </template>

    <Tabs :titles="['Project Details', 'Tasks & Resources']">
      <template #tab-1>
        <div class="grid-2">
          <Panel
              title="Details"
          >
            <div class="project-detail">
              <span>Title:</span>
              <span>{{ project.title }}</span>
            </div>
            <hr>
            <div class="project-detail">
              <span>Description:</span>
              <span>{{ project.description ?? '-' }}</span>
            </div>
            <hr>
            <div class="project-detail">
              <span>Start Date:</span>
              <span>{{ moment(project.start_date).format('DD/MM/YYYY') }}</span>
            </div>
            <hr>
            <div class="project-detail">
              <span>End Date:</span>
              <span>{{ moment(project.end_date).format('DD/MM/YYYY') }}</span>
            </div>
            <hr>
            <div class="project-detail">
              <span>Duration:</span>
              <span>{{ duration(project.start_date, project.end_date) }}</span>
            </div>
          </Panel>
          <Panel
            title="Team"
        >
          <div class="project-team" v-for="member in project.users" :key="member.id">
            <span>
              <img class="uk-border-circle outline" src="../../assets/default-avatar.png" height="56" width="56"
                   alt="Avatar"/>
              <span>
                {{ member.first_name }} {{ member.last_name }} {{ member.id === user.id ? '(You)' : '' }}
              </span>
            </span>
            <span>
                <span v-if="user.id !== project.user_id" :style="'display: block; height: 40px; width: 40px; background-color: ' + member.pivot.colour"></span>
                <Input v-else v-model="member.pivot.colour" type="color" @change="updateMember(member.pivot)" />
              </span>
          </div>
        </Panel>
        </div>
      </template>
      <template #tab-2>
        <Accordion open title="Tasks">
          <progress class="uk-progress uk-margin-small-bottom" :value="completedCount" :max="taskCount"></progress>
          <div class="project-info-tips">
            <div>
              <span class="in-progress">In Progress</span>
              <span class="over-deadline">Exceeded Deadline</span>
            </div>
            <div>{{ completedCount }} / {{ taskCount }}</div>
          </div>
          <Table class="project-table">
            <tr class="project-table-header">
              <th>Name</th>
              <th>Start/End Date</th>
              <th>Assignee</th>
              <th class="column-small">Completed</th>
              <th class="column-small"></th>
            </tr>
            <tbody>
            <tr class="row" v-for="task in project.tasks" :key="task.id"
                :style="'background: linear-gradient(90deg, '+ getColour(task.assignee) +' 0%,' + [task.completed ? 'var(--successColour)' : 'transparent'] + ' 25%);'">
              <td :style="isBright(getColour(task.assignee)) ? '' : 'color: white;'">{{ task.name }}</td>
              <td>
                  <span v-if="!task.completed" :class="getTasksProgress(moment(task.start_date), moment(task.end_date))">
                    {{ moment(task.start_date).format('DD/MM/YYYY') }}
                    -
                    {{ moment(task.end_date).format('DD/MM/YYYY') }}
                  </span>
                <span v-else class="text-white">
                    {{ moment(task.start_date).format('DD/MM/YYYY') }}
                    -
                    {{ moment(task.end_date).format('DD/MM/YYYY') }}
                  </span>
              </td>
              <td>
                <span v-if="user.id !== project.user_id">{{ getMember(task.assignee) }}</span>
                <select v-else class="uk-select uk-border-rounded uk-form-small" v-model="task.assignee" :disabled="task.completed" @change="taskFunc(task, true)">
                  <option v-for="member in project.users" :key="member.id" :value="member.id">
                    {{ member.first_name }} {{ member.last_name }}
                  </option>
                </select>
              </td>
              <td class="column-small">
                <input v-if="user.id === project.user_id || user.id === task.assignee" v-model="task.completed" type="checkbox" name="checkbox" class="uk-checkbox" @click="task.completed = !task.completed; taskFunc(task, true)">
                <span v-else>
                    <i v-if="task.completed" class="fa-solid fa-check"></i>
                    <i v-else class="fa-solid fa-xmark"></i>
                  </span>
              </td>
              <td>
                  <span v-if="!task.completed && user.id === project.user_id" class="btn btn-icon-danger" @click="taskFunc(task, true, true)">
                    <i class="fa-solid fa-trash"></i>
                  </span>
              </td>
            </tr>
            <tr v-if="user.id === project.user_id" class="row">
              <td>
                <Input type="text" v-model="newTask.name" />
              </td>
              <td>
                <Datepicker
                    v-model="newTask.date"
                    format="dd/MM/yyyy"
                    range
                    multiCalendars
                    :enableTimePicker="false"
                    placeholder="Start/End Date"
                    :min-date="project.start_date"
                    showNowButton
                />
              </td>
              <td>
                <select class="uk-select uk-border-rounded" v-model="newTask.assignee">
                  <option :value="null">Select Member</option>
                  <option v-for="member in project.users" :key="member.id" :value="member.id">
                    {{ member.first_name }} {{ member.last_name}}
                  </option>
                </select>
              </td>
              <td></td>
              <td>
                <a class="btn btn-icon-success" @click="taskFunc()"><i class="fas fa-plus"></i></a>
              </td>
            </tr>
            </tbody>
          </Table>
        </Accordion>
        <Accordion v-if="project.resource" open title="Resource List">
          <Table class="project-table">
            <tr class="project-table-header">
              <th class="column-medium">Quantity</th>
              <th>Units</th>
              <th>Name</th>
              <th class="column-medium">Collected</th>
              <th class="column-medium"></th>
            </tr>
            <tbody>
            <tr class="row" v-for="resource in project.resources" :key="resource.id" :class="resource.collected ? 'completed' : ''">
              <td>
                <span>{{ resource.quantity }}</span>
                <span>x</span>
              </td>
              <td>{{ resource.unit }}</td>
              <td>{{ resource.name }}</td>
              <td class="column-small">
                  <span v-if="user.id !== project.user_id">
                    <i v-if="resource.collected" class="fa-solid fa-check"></i>
                    <i v-else class="fa-solid fa-xmark"></i>
                  </span>
                <input v-else v-model="resource.collected" type="checkbox" name="checkbox" class="uk-checkbox" @change="resourceFunc(resource, true)">
              </td>
              <td class="column-small">
                  <span v-if="!resource.collected && user.id === project.user_id" class="btn btn-icon-danger" @click="resourceFunc(resource, true, true)">
                    <i class="fa-solid fa-trash"></i>
                  </span>
              </td>
            </tr>
            <tr class="row" v-if="user.id === project.user_id">
              <td><Input v-model="newResource.quantity" type="number" /></td>
              <td>
                <select v-model="newResource.units" class="uk-select uk-border-rounded">
                  <option :value="null" hidden>Select Units</option>
                  <option value="unit">unit</option>
                  <option value="kg">kg</option>
                  <option value="lb">lb</option>
                  <option value="m">m</option>
                  <option value="cm">cm</option>
                  <option value="mm">mm</option>
                  <option value="mm">ml</option>
                  <option value="mm">l</option>
                </select>
              </td>
              <td>
                <Input v-model="newResource.name" type="text" />
              </td>
              <td></td>
              <td class="column-small">
                <a class="btn btn-icon-success" @click="resourceFunc()"><i class="fas fa-plus"></i></a>
              </td>
            </tr>
            </tbody>
          </Table>
        </Accordion>
      </template>
    </Tabs>
  </Authenticated>

  <Modal
      v-if="completedModal"
      :confirm="true"
      :title="completedMessage"
      @closeModal="completedModal = false"
      @confirm="completed"
  >

  </Modal>
</template>

<style scoped>
.project-info-tips {
  display: flex;
  justify-content: space-between;
}
.in-progress {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 10px;
  border-radius: 500px;
  vertical-align: middle;
  background: var(--infoColour);
  color: #fff!important;
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-right: 10px;
}
.over-deadline {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  background: var(--dangerColour);
  color: #fff!important;
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}
.completed {
  background-color: var(--successColour);
  color: white;
}

.grid-2 > div {
  height: fit-content;
}
.project-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.project-table-header > th {
  height: 50px;
  font-weight: normal !important;
}
.row {
  border-top: 1px solid #EEEEEE !important;
  height: 50px;
}
.column-small {
  width: 28px;
  text-align: center;
}
.column-medium {
  width: 100px;
}
td {
  padding: 0 5px;
}

.project-detail {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.project-detail > span:first-child {
  font-weight: 700;
}

.project-team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.project-team:last-child {
  margin-bottom: 0;
}
.project-team > span:first-child {
  display: flex;
  align-items: center;
  gap: 10px;
}
.project-team > span:last-child > * {
  height: 40px;
  width: 40px!important;
}
</style>
