import { createApp } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import VueApexCharts from 'vue3-apexcharts'
import moment from 'moment'
import axios from 'axios'
import App from './App.vue'
import router from './router'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1'
} else {
  axios.defaults.baseURL = 'https://covenant-system.co.uk/api/v1'
}

createApp(App)
  .use(router, axios, Datepicker, VueApexCharts, moment)
  .mount('#app')
