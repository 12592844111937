<script setup>
import Widget from '@/components/section/widgets/WidgetComponent.vue'
import axios from 'axios'
import moment from 'moment'
import DashboardServices from '@/utils/services/dashboardServices'
import { ref } from 'vue'

const service = new DashboardServices()
const sports = ref(null)

const getBasicSports = () => {
  axios.get('https://ipapi.co/json/')
    .then((response) => {
      const ip = response.data.ip
      service.getBasicSports(ip).then((response) => {
        sports.value = []
        response.data.football.forEach(x => {
          if (x.tournament === 'Premier League') {
            sports.value.push(x)
          }
        })
      }).catch(() => {
        sports.value = false
      })
    })
}

getBasicSports()

</script>

<template>
  <Widget>
    <template #title>Sports</template>
    <template #controls></template>
    <template #content>
      <div v-if="sports && sports.length > 0" class="sports">
        <div v-for="(sport, index) in sports" :key="index" class="sport">
          <div class="team-info">
            <div>{{ sport.match }}</div>
            <div>{{ sport.tournament }}</div>
          </div>
          <div class="match-info">
            <div>{{ sport.stadium }}</div>
            <div>{{ moment(sport.start).format('Do MMM HH:mm') }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        There are no sports event taking place.
      </div>
    </template>
  </Widget>
</template>

<style scoped>
.sports {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sport {
  display: flex;
  justify-content: space-between;
  background-color: rgba(100, 100, 100, 0.5);
  padding: 5px 10px;
  border-radius: 10px;
}
.team-info {
  display: flex;
  flex-direction: column;
}
.team-info > div:first-child {
  font-weight: bold;
}
.match-info {
  display: flex;
  flex-direction: column;
  text-align: right;
}
</style>
