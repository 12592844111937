import axios from 'axios'

class WishlistServices {
  /* Wishlist Requests */
  async getList (userId) {
    return await axios.get('/get-lists/' + userId)
  }

  async createList (userId, data) {
    return await axios.post('/create-list/' + userId, data)
  }

  async deleteList (listId) {
    return await axios.delete('/delete-list/' + listId)
  }

  async activateList (listId) {
    return await axios.post('/activate-list/' + listId)
  }

  async submitInvite (listId, friendId) {
    return await axios.patch('submit-invite/' + listId + '/' + friendId)
  }

  async submitReserveTime (listId, data) {
    return await axios.patch('/reserve-time/' + listId, data)
  }

  async expired (listId, userId, data) {
    return await axios.post('/expired/' + listId + '/' + userId, data)
  }

  /* Wishlist Item Requests */
  async getItems (listId) {
    return await axios.get('/wishlist-items/' + listId + '/getItems')
  }

  async addItem (listId, data) {
    return await axios.post('/wishlist-items/' + listId + '/addItem', data)
  }

  async reserveItem (itemId, userId) {
    return await axios.patch('/wishlist-items/' + itemId + '/reserve/' + userId)
  }

  async boughtItem (itemId, userId, data) {
    return await axios.patch('/wishlist-items/' + itemId + '/bought/' + userId, data)
  }

  async updateItem (itemId, data) {
    return await axios.patch('/wishlist-items/' + itemId, data)
  }

  async deleteItem (itemId) {
    return await axios.delete('/wishlist-items/' + itemId)
  }

  /* Template Item Requests */
  async getTemplateItems (userId) {
    return await axios.get('/get-template-items/' + userId)
  }

  async addTemplateItem (userId, data) {
    return await axios.post('/create-template-item/' + userId, data)
  }

  async updateTemplateItem (itemId, userId, data) {
    return await axios.patch('/update-template-item/' + itemId + '/' + userId, data)
  }

  async deleteTemplateItem (itemId, userId) {
    return await axios.delete('/delete-template-item/' + itemId + '/' + userId)
  }
}

export default WishlistServices
