import axios from 'axios'

class DashboardServices {
  weather = {
    location: {
      name: 'Swadlincote',
      region: 'Derbyshire',
      country: 'United Kingdom',
      lat: 52.87,
      lon: -1.64,
      tz_id: 'Europe/London',
      localtime_epoch: 1679867131,
      localtime: '2023-03-26 22:45'
    },
    current: {
      last_updated_epoch: 1679867100,
      last_updated: '2023-03-26 22:45',
      temp_c: 5,
      temp_f: 41,
      is_day: 0,
      condition: {
        text: 'Partly cloudy',
        icon: '//cdn.weatherapi.com/weather/64x64/night/116.png',
        code: 1003
      },
      wind_mph: 4.3,
      wind_kph: 6.8,
      wind_degree: 340,
      wind_dir: 'NNW',
      pressure_mb: 1019,
      pressure_in: 30.09,
      precip_mm: 0,
      precip_in: 0,
      humidity: 75,
      cloud: 25,
      feelslike_c: 3.1,
      feelslike_f: 37.5,
      vis_km: 10,
      vis_miles: 6,
      uv: 1,
      gust_mph: 9.4,
      gust_kph: 15.1,
      air_quality: {
        co: 247,
        no2: 6,
        o3: 74.4000015258789,
        so2: 1.7999999523162842,
        pm2_5: 3,
        pm10: 3.5999999046325684,
        'us-epa-index': 1,
        'gb-defra-index': 1
      }
    }
  }

  async getData (userId) {
    return await axios.get('/dashboard/' + userId)
  }

  async addFriend (userId, friendId) {
    return await axios.post('/add-friend/' + userId + '/' + friendId)
  }

  async acceptRequest (userId, friendId) {
    return await axios.post('/accept-request/' + userId + '/' + friendId)
  }

  async declineRequest (userId, friendId) {
    return await axios.delete('/decline-request/' + userId + '/' + friendId)
  }

  async cancelRequest (userId, friendId) {
    return await axios.delete('/cancel-request/' + userId + '/' + friendId)
  }

  async removeFriend (userId, friendId) {
    return await axios.delete('/remove-friend/' + userId + '/' + friendId)
  }

  async getBasicWeather (ip) {
    return await axios.get(
      'http://api.weatherapi.com/v1/forecast.json?key=9969d55fd8c14635946184134230912&q=' +
            ip + '&days=1&aqi=no&alerts=no'
    )
  }

  async getAdvancedWeather (ip) {
    return await axios.get(
      'http://api.weatherapi.com/v1/forecast.json?key=9969d55fd8c14635946184134230912&q=' +
            ip + '&days=5&aqi=no&alerts=no'
    )
  }

  async getBasicSports (ip) {
    return await axios.get(
      'http://api.weatherapi.com/v1/sports.json?key=9969d55fd8c14635946184134230912&q=' +
            ip
    )
  }
}

export default DashboardServices
