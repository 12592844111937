<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Button, Tabs } from '../../components/index'
import ProfileTab from '../settings/profile/ProfileTab.vue'
import SettingsTab from '../settings/settings/SettingsTab.vue'
import SystemVersions from '../settings/settings/modals/SystemVersions.vue'
import PreferencesTab from '../settings/preferences/PreferencesTab.vue'
import { ref } from 'vue'

const systemVersionsModal = ref(false)
</script>

<template>
  <Authenticated>
    <template #header>
      My Account
      <Button action="dark" @click="systemVersionsModal = true">
        System Versions
      </Button>
    </template>
    <Tabs :titles="['Profile', 'Preferences', 'Settings']">
      <template #tab-1>
        <ProfileTab />
      </template>
      <template #tab-2>
        <PreferencesTab />
      </template>
      <template #tab-3>
        <SettingsTab />
      </template>
    </Tabs>

  </Authenticated>
  <SystemVersions
      v-if="systemVersionsModal"
      @closeModal="systemVersionsModal = false"
  />
</template>

<style scoped>

</style>
