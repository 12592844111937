<script setup>
import Authenticated from '@/pages/layouts/AuthenticatedLayout.vue'
import { Button, Dropdown, Sidebar } from '@/components/index'
import CurrentEvent from './components/CurrentEvent.vue'
import WishlistEvent from './components/WishlistEvent.vue'
import ProjectEvent from './components/ProjectEvent.vue'
import NewEventModal from '../calendar/modals/NewEventModal.vue'
import EditEventModal from '../calendar/modals/EditEventModal.vue'
import CalendarServices from '@/utils/services/calendarServices'
import moment from 'moment'
import { ref } from 'vue'
import { store } from '@/store'

const user = store.user
const today = moment()
const month = ref([])
const currentMonth = ref(today)
const firstDay = ref(null)
const friends = ref(null)
const currentEvent = ref(null)
const service = new CalendarServices()
const activeMonth = ref(currentMonth.value.format('M'))
const activeYear = ref(currentMonth.value.format('YYYY'))
const newEvent = ref(false)
const eventInfo = ref(false)
const editEvent = ref(false)

const getYears = () => {
  const fromDate = moment('2023-01-01')
  const toDate = moment().add(10, 'years')
  const diff = toDate.diff(fromDate, 'years')
  const range = []
  for (let i = 0; i < diff; i++) {
    range.push(moment('2023-01-01').add(i, 'years').format('YYYY'))
  }
  return range
}

const selectedDate = () => {
  currentMonth.value = moment(activeYear.value + '-' + activeMonth.value + '-01')
  getCalendarMonth(currentMonth.value)
}

const getCalendarMonth = (currentMonth) => {
  service.getCalendar(user.id, currentMonth)
    .then((response) => {
      month.value = response.data.month
      friends.value = response.data.friends
      firstDay.value = moment(month.value[0].date).startOf('month').day() - 1
      if (firstDay.value === -1) {
        firstDay.value = 6
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

getCalendarMonth(currentMonth.value)

const nextMonth = () => {
  currentMonth.value = moment(currentMonth.value).add(1, 'month')
  activeMonth.value = currentMonth.value.format('M')
  getCalendarMonth(currentMonth.value)
}

const preMonth = () => {
  currentMonth.value = moment(currentMonth.value).subtract(1, 'month')
  activeMonth.value = currentMonth.value.format('M')
  getCalendarMonth(currentMonth.value)
}

const getCurrentEvent = (event) => {
  currentEvent.value = event
}
</script>

<template>
  <Authenticated>
    <template #header>
      <span>Calendar</span>
      <Button action="dark" @click="newEvent = true">
        <i class="fas fa-plus"></i> Event
      </Button>
    </template>

    <div class="uk-card-default uk-padding-small uk-border-rounded uk-overflow-auto">
      <div class="calendar">
        <div class="uk-flex uk-flex-between">
          <div class="uk-padding-small icon" @click="preMonth"><i class="fa-solid fa-chevron-left"></i></div>
          <div class="uk-flex uk-width-1-3">
            <select class="uk-select uk-border-rounded uk-margin-small-right" v-model="activeMonth" @change="selectedDate">
              <option
                  v-for="(month, key) in moment.months()"
                  :key="key" :value="key+1"
              >
                {{ month }}
              </option>
            </select>
            <select class="uk-select uk-border-rounded" v-model="activeYear" @change="selectedDate">
              <option
                  v-for="year in getYears()"
                  :key="year" :value="year"
              >{{ year }}</option>
            </select>
          </div>
          <div class="uk-padding-small icon" @click="nextMonth"><i class="fa-solid fa-chevron-right"></i></div>
        </div>
        <div class="calendar-header uk-margin-small-bottom">
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div class="uk-text-primary">Sat</div>
          <div class="uk-text-primary">Sun</div>
        </div>
        <div class="calendar-grid">
          <div v-for="start in firstDay" :key="start"></div>
          <div v-for="day in month" :key="day.date">
            <div class="border uk-text-center day">
              <p class="uk-margin-small-bottom"
                 :class="[
                     moment(day.date).isoWeekday() === 6 || moment(day.date).isoWeekday() === 7 ? 'uk-text-primary': '',
                     moment(day.date).format('DD MM YYYY') === moment().format('DD MM YYYY') ? 'today' : ''
                 ]">
                {{ moment(day.date).format('Do') }}
              </p>
              <div v-if="day.events">
                <Dropdown v-if="day.events[0] && day.events[0].type === 'birthdays'" class="marker">
                  <template #display></template>
                  <template #content><span class="marker-content">{{ day.events[0].event.first_name }} Birthday</span></template>
                </Dropdown>
                <div v-for="event in day.events" :key="event">
                  <div v-if="event.event !== undefined"
                       :class="[
                           'clickable',
                           event.event.start_date === event.event.end_date ? 'one-day-event' : '',
                           event.event.start_date === undefined ? 'one-day-event' : '',
                           moment(event.event.start_date).format('YYYY-MM-DD') === day.date ? 'start-event' : '',
                           moment(event.event.end_date).format('YYYY-MM-DD') === day.date ? 'end-event' : '',
                           event.type
                       ]"
                       @click="getCurrentEvent(event); eventInfo = true"
                  >
                    {{ event.name }}
                  </div>
                  <div v-else :class="event.type" class="one-day-event">
                    {{ event.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Sidebar v-if="eventInfo"
             :title="currentEvent.event?.title"
             @closeModal="eventInfo = false"
    >
      <CurrentEvent v-if="currentEvent.type === 'events'" :currentEvent="currentEvent.event" @editModal="editEvent = true; eventInfo = false;" />
      <WishlistEvent v-if="currentEvent.type === 'lists'" :currentEvent="currentEvent.event" />
      <ProjectEvent v-if="currentEvent.type === 'project'" :currentEvent="currentEvent.event" />
    </Sidebar>
  </Authenticated>

  <NewEventModal v-if="newEvent" :friends="friends" @closeModal="newEvent = false"/>
  <EditEventModal v-if="currentEvent && editEvent" :event="currentEvent" :friends="friends" @closeModal="editEvent = false" />
</template>

<style scoped>
.calendar {
  position: relative;
  min-width: 500px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 5px;
  border-radius: 10px;
}
.clickable {
  cursor: pointer;
}
.today {
  background-color: var(--primaryColour);
  color: var(--bodyColour)!important;
  border-radius: 0 0 10px 10px;
  padding: 3px 5px;
  width: fit-content;
  margin: 0 auto 4px auto !important;
}
.day {
  background-color: #eeeeee;
  height: 100%;
  padding-bottom: 2px;
  border-radius: 10px;
  min-height: 100px;
  position: relative;
}
.one-day-event {
  margin: 1px 5px 5px 5px !important;
  border-radius: 20px !important;
  font-size: 13px;
}
.start-event {
  margin-left: 5px !important;
  margin-top: 4px !important;
  margin-bottom: 5px !important;
  border-radius: 20px 0 0 20px;
  font-size: 13px;
}
.end-event {
  margin-right: 5px !important;
  margin-top: 4px !important;
  margin-bottom: 5px !important;
  border-radius: 0 20px 20px 0;
  font-size: 13px;
}
.marker {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #009400;
}
.marker-content {
  color: var(--bodyColour);
  white-space: nowrap;
}
.bank-holiday {
  background: rgb(132, 225, 188, 0.62);
  color: var(--tertiaryColour);
}
.events {
  background: rgba(122, 212, 231, 0.64);
  color: var(--tertiaryColour);
  margin: 1px 0 5px 0;
  font-size: 13px;
}
.lists {
  background: rgb(250, 202, 21);
  color: var(--tertiaryColour);
}
.project {
  background: rgb(248, 100, 100);
  color: var(--tertiaryColour);
  margin: 1px 0 5px 0;
  font-size: 13px;
}
</style>
