<script setup>
import { ref } from 'vue'
import moment from 'moment'
import Widget from '@/components/section/widgets/WidgetComponent.vue'
import axios from 'axios'
import DashboardServices from '@/utils/services/dashboardServices'

const service = new DashboardServices()
const weather = ref(null)
const basic = ref(true)

const getBasicWeather = () => {
  axios.get('https://ipapi.co/json/')
    .then((response) => {
      const ip = response.data.ip
      service.getBasicWeather(ip).then((response) => {
        weather.value = response.data.forecast.forecastday[0]
      }).catch(() => {
        weather.value = false
      })
    })

  basic.value = true
}

const getAdvancedWeather = () => {
  weather.value = []
  axios.get('https://ipapi.co/json/')
    .then((response) => {
      const ip = response.data.ip
      service.getAdvancedWeather(ip).then((response) => {
        response.data.forecast.forecastday.forEach(x => {
          weather.value.push({
            date: x.date,
            day: x.day
          })
        })
      }).catch(() => {
        weather.value = false
      })
    })
  basic.value = false
}

getBasicWeather()

</script>

<template>
  <Widget>
    <template #title></template>
    <template #controls>
      <div class="weather-view">
        <span :class="basic ? 'active-pill' : 'pill'" @click="getBasicWeather">Today</span>
        <span :class="!basic ? 'active-pill' : 'pill'" @click="getAdvancedWeather">3 Days</span>
      </div>
    </template>

    <template #content>
      <div v-if="weather && basic" class="weather">
        <div>
          <div class="weather-content">
            <div class="weather-content-left">
              <img height="60" width="60" :src="weather.day.condition.icon" alt="weather icon">
              <div>{{ weather.day.condition.text }}</div>
            </div>
            <div class="weather-content-right">
              <div>{{ weather.day.avgtemp_c }}°C</div>
              <div><span class="small-text"><i class="fa-solid fa-droplet"></i></span> {{ weather.day.daily_chance_of_rain }}%</div>
            </div>
          </div>
          <div class="weather-sub-content">
            <div><span class="small-text">Sun Rise: </span> {{ weather.astro.sunrise }}</div>
            <div><span class="small-text">Sun Set: </span> {{ weather.astro.sunset }}</div>
          </div>
        </div>
      </div>
      <div v-if="weather && !basic" class="advanced-weather">
        <div v-for="(day, index) in weather" :key="index" class="weather-date">
          <img height="60" width="60" :src="day.day.condition.icon" alt="weather icon">
          <div>{{ day.day.condition.text }}</div>
          <div>{{ moment(day.date).format('Do MMMM') }}</div>
          <div>{{ day.day.avgtemp_c }}°C</div>
        </div>
      </div>
    </template>
  </Widget>
</template>

<style scoped>
.advanced-weather {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.weather-date {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: capitalize;
  background-color: rgba(100, 100, 100, 0.5);
}
.weather-date > img {
  margin: 0 auto;
}

.weather-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
}
.weather-content-left {
  display: flex;
  gap: 10px;
  text-transform: capitalize;
  font-size: 20px;
}
.weather-content-left > div {
  margin: auto 0;
}
.weather-content-right {
  text-align: right;
  font-size: 20px;
}
.weather-sub-content {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.small-text {
  font-size: 14px;
}

.weather-view {
  display: flex;
  gap: 10px;
  font-size: 14px;
}
.active-pill {
  padding: 2px 5px;
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 10px;
  cursor: pointer;
}
.pill {
  padding: 2px 5px;
  cursor: pointer;
}
</style>
