import axios from 'axios'

class SettingServices {
  async updateProfileInformation (userId, data) {
    return await axios.patch('/update-profile-information/' + userId, data)
  }

  async updateEmail (userId, data) {
    return await axios.patch('/update-email/' + userId, data)
  }

  async updatePassword (userId, data) {
    return await axios.patch('/update-password/' + userId, data)
  }

  async getProfile (userId) {
    return await axios.get('/get-profile/' + userId)
  }

  async updateSocials (userId, data) {
    return await axios.post('/update-socials/' + userId, data)
  }

  async updateAbout (userId, data) {
    return await axios.post('/update-about/' + userId, data)
  }

  async updatePreferences (userId, data) {
    return await axios.post('/update-preference/' + userId, data)
  }
}

export default SettingServices
