<script setup>
import { Button, Input, Panel } from '@/components/index'
import SettingServices from '@/utils/services/settingServices'
import Datepicker from '@vuepic/vue-datepicker'
import { store } from '@/store'
import router from '@/router'
import { ref } from 'vue'

const user = store.user
const service = new SettingServices()
const profile = ref({
  first_name: user.first_name,
  last_name: user.last_name,
  date_of_birth: user.date_of_birth
})
const emails = ref({
  old: user.email,
  new: null
})
const passwords = ref({
  old: null,
  new: null,
  conf: null
})
const errors = ref({
  personal: {},
  email: {},
  password: {}
})

const updateProfileInformation = () => {
  let tempError = 0

  if (!profile.value.first_name) {
    tempError++
    errors.value.personal.first_name = 'This field is required'
  }
  if (!profile.value.last_name) {
    tempError++
    errors.value.personal.last_name = 'This field is required'
  }
  if (!profile.value.date_of_birth) {
    tempError++
    errors.value.personal.date_of_birth = 'This field is required'
  }

  if (tempError > 0) {
    return
  }

  service.updateProfileInformation(user.id, profile.value)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.user))
      router.go()
    })
}

const updateEmail = () => {
  if (!emails.value.new) {
    errors.value.email.newEmailError = 'This is a required field'
    return
  }

  errors.value.email = {}

  service.updateEmail(user.id, emails.value)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.user))
      router.go()
    })
}

const updatePassword = () => {
  if (!passwords.value.old || !passwords.value.new || !passwords.value.conf) {
    return
  }

  errors.value.password = {}

  service.updatePassword(user.id, passwords.value)
    .then(() => {
    // Notification
      passwords.value = {
        old: null,
        new: null,
        conf: null
      }
    }).catch((error) => {
      if (error.response.data.currentPasswordError) {
        errors.value.password.currentPasswordError = error.response.data.currentPasswordError
      }

      if (error.response.data.confirmPassword) {
        errors.value.password.confirmPasswordError = error.response.data.confirmPassword
      }

      passwords.value = {
        old: null,
        new: null,
        conf: null
      }
    })
}

</script>

<template>
  <Panel title="Profile Information">
    <div class="grid-2">
      <div>
        First Name <span class="text-danger">{{ errors.personal['first_name'] }}</span>
        <Input type="text" v-model="profile.first_name" />
      </div>
      <div>
        Last Name <span class="text-danger">{{ errors.personal['last_name'] }}</span>
        <Input type="text" v-model="profile.last_name" />
      </div>
      <div>
        Date of Birth <span class="text-danger">{{ errors.personal['date_of_birth'] }}</span>
        <Datepicker
            v-model="profile.date_of_birth"
            format="dd/MM/yyyy"
            :enableTimePicker="false"
            placeholder="Date of Birth"
        />
      </div>
      <div>
        Theme
        <div>
          <select class="uk-select uk-border-rounded" disabled>
            <option>Light</option>
          </select>
        </div>
      </div>
    </div>
    <div class="action">
      <Button dark action="success" @click.prevent="updateProfileInformation()">
        Submit
      </Button>
    </div>
  </Panel>
  <Panel title="Change Email">
    <div class="grid-2">
      <div>
        Current Email
        <Input disabled type="text" v-model="emails.old" />
      </div>
      <div>
        New Email <span class="text-danger">{{ errors.email['newEmailError'] }}</span>
        <Input type="text" v-model="emails.new" />
      </div>
    </div>
    <div class="action">
      <Button dark action="success" @click.prevent="updateEmail">
        Submit
      </Button>
    </div>
  </Panel>
  <Panel title="Change Password">
    <div class="grid-3">
      <div>
        Current Password <span class="text-danger">{{ errors.password['currentPasswordError'] }}</span>
        <Input type="password" v-model="passwords.old" />
      </div>
      <div>
        New Password <span class="text-danger">{{ errors.password['confirmPasswordError'] }}</span>
        <Input type="password" v-model="passwords.new" />
      </div>
      <div>
        Confirm New Password <span class="text-danger">{{ errors.password['confirmPasswordError'] }}</span>
        <Input type="password" v-model="passwords.conf" />
      </div>
    </div>
    <div class="action">
      <Button dark action="success" @click.prevent="updatePassword()">
        Submit
      </Button>
    </div>
  </Panel>
</template>

<style scoped>
.action {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
}
</style>
