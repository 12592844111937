<script setup>
import { defineProps } from 'vue'
import moment from 'moment'
import { store } from '@/store'
import Widget from '@/components/section/widgets/WidgetComponent.vue'

defineProps(['wishlists'])

const removeInText = (text) => {
  text = text.split(' ')
  return {
    number: text[1],
    text: text[2]
  }
}

const boughtItems = (items) => {
  let count = 0
  items.forEach(x => {
    if (x.bought_by === store.user.id) {
      count++
    }
  })
  return count
}

</script>

<template>
  <Widget>
    <template #title>
      Wishlists
    </template>
    <template #controls>

    </template>

    <template #content v-if="wishlists !== null">
      <div v-if="wishlists.length > 0">
        <div v-for="wishlist in wishlists" :key="wishlist.id" class="wishlist">
          <div class="wishlist-content">
            <div class="wishlist-info">
              <div>
                {{ removeInText(moment(wishlist.end_date).fromNow()).number }}
              </div>
              <div>
                {{ removeInText(moment(wishlist.end_date).fromNow()).text }}
              </div>
            </div>
            <div class="wishlist-title">
              {{ wishlist.title }}
              <div class="wishlist-sub-text">
                {{ moment(wishlist.end_date).format('Do MMMM YYYY') }}
              </div>
            </div>
          </div>
          <div class="wishlist-detail">
            {{ boughtItems(wishlist.wishlist_items) > 0 ? 'Bought' : 'Not Bought' }}
          </div>
        </div>
      </div>
      <div v-else>
        There are no wishlists
      </div>
    </template>
  </Widget>
</template>

<style scoped>
.wishlist {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 5px;
}
.wishlist:hover {
  background-color: rgba(100, 100, 100, 0.5);
}

.wishlist-content {
  display: flex;
  gap: 20px;
}

.wishlist-title {
  font-size: 16px;
  font-weight: bold;
}
.wishlist-sub-text {
  font-size: 14px;
  font-weight: normal;
}

.wishlist-info:nth-child(n) {
  font-size: 14px;
  width: 60px;
  display: block;
  background-color: var(--secondaryColour);
  border-radius: 10px;
  padding: 3px;
  text-transform: capitalize;
  text-align: center;
}
.wishlist-detail {
  text-align: right;
}
</style>
