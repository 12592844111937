<script setup>
import { defineProps, ref, onBeforeMount } from 'vue'
import Widget from '@/components/section/widgets/WidgetComponent.vue'
import moment from 'moment/moment'
import { store } from '@/store'

let props = defineProps([
  'upcomingEvents'
])

const thisUser = ref([])

const removeInText = (text) => {
  text = text.split(' ')
  return {
    number: text[1],
    text: text[2]
  }
}

onBeforeMount(() => {
  props.upcomingEvents.forEach((e) => {
    thisUser.value.push(e.users.find((user) => user.id === store.user.id));
  });
})

</script>

<template>
  <Widget>
    <template #title>
      Calendar
    </template>
    <template #controls>

    </template>

    <template #content v-if="upcomingEvents !== null">
      <div v-if="upcomingEvents.length > 0">
        <div v-for="(event, index) in upcomingEvents" :key="event.id" class="event">
          <div class="event-content">
            <div class="event-info">
              <div>
                {{ removeInText(moment(event.start_date).fromNow()).number }}
              </div>
              <div>
                {{ removeInText(moment(event.start_date).fromNow()).text }}
              </div>
            </div>
            <div class="event-title">
              {{ event.title }}
              <div class="event-sub-text">
                {{ moment(event.start_date).format('Do MMMM YYYY') }}
              </div>
            </div>
          </div>
          <div>
            <span v-if="thisUser[index].id === event.user_id" class="text-success">
              Going
            </span>
            <span v-else>
              <span class="text-info" v-if="!thisUser[index].pivot.decision">Pending Answer</span>
              <span class="text-success" v-if="thisUser[index].pivot.decision === 'yes'">Going</span>
              <span class="text-danger" v-if="thisUser[index].pivot.decision === 'no'">Not Going</span>
              <span class="text-warning" v-if="thisUser[index].pivot.decision === 'maybe'">Maybe</span>
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        There are no upcoming events
      </div>
    </template>
  </Widget>
</template>

<style scoped>
.event {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 5px;
}
.event:hover {
  background-color: rgba(100, 100, 100, 0.5);
}

.event-content {
  display: flex;
  gap: 20px;
}

.event-title {
  font-size: 16px;
  font-weight: bold;
}
.event-sub-text {
  font-size: 14px;
  font-weight: normal;
}

.event-info:nth-child(n) {
  font-size: 14px;
  width: 60px;
  display: block;
  background-color: var(--secondaryColour);
  border-radius: 10px;
  padding: 3px;
  text-transform: capitalize;
  text-align: center;
}
</style>
