<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Button, Card, Tabs } from '@/components/index'
import CreateTicket from '../feedback/components/CreateTicket.vue'
import ViewTicket from '../feedback/components/ViewTicket.vue'
import FeedbackServices from '@/utils/services/feedbackServices'
import { ref } from 'vue'
import { store } from '@/store'
import Panel from '@/components/PanelComp.vue'

const selectedVue = ref('list-tickets')
const isLoading = ref(true)
const service = new FeedbackServices()
const sections = ref(null)
const types = ref(null)
const tickets = ref(null)
const resolvedSupports = ref(null)
const supports = ref(null)
const activeIssue = ref(null)
const sectionFilter = ref([])
const typeFilter = ref([])
const statusFilter = ref(null)
const user = store.user

const getIssues = (query = null) => {
  service.getIssues(user.id, query)
    .then((response) => {
      tickets.value = response.data.tickets
      resolvedSupports.value = response.data.resolvedSupports
      supports.value = response.data.supports
      sections.value = response.data.sections
      types.value = response.data.types
      isLoading.value = false
    })
}

getIssues()

const getSelectedIssue = (issue) => {
  activeIssue.value = issue
  selectedVue.value = 'view-ticket'
}

const submittedForm = () => {
  selectedVue.value = 'list-tickets'
  getIssues()
}

const back = () => {
  selectedVue.value = 'list-tickets'
  getIssues()
}

const filter = (value, type) => {
  if (type === 'section') {
    if (sectionFilter.value.includes(value)) {
      const index = sectionFilter.value.indexOf(value)
      sectionFilter.value.splice(index, 1)
    } else {
      sectionFilter.value.push(value)
    }
  } else if (type === 'status') {
    if (statusFilter.value === value) {
      statusFilter.value = ''
    } else {
      statusFilter.value = value
    }
  } else {
    if (typeFilter.value.includes(value)) {
      const index = typeFilter.value.indexOf(value)
      typeFilter.value.splice(index, 1)
    } else {
      typeFilter.value.push(value)
    }
  }
  const query = '?section=' + [sectionFilter.value ?? ''] + '&type=' + [typeFilter.value ?? ''] + '&status=' + [statusFilter.value ?? '']
  isLoading.value = true
  getIssues(query)
}

</script>

<template>
  <Authenticated>
    <template #header>
      <span>Feedback</span>
      <Button action="info" v-if="selectedVue === 'create-ticket'" @click="selectedVue = 'list-tickets'">
        Cancel
      </Button>
      <Button action="info" v-if="selectedVue === 'view-ticket'" @click="back">
        <i class="fa-solid fa-arrow-left"></i> Back
      </Button>
      <Button action="dark" v-if="selectedVue === 'list-tickets'" @click="selectedVue = 'create-ticket'">
        <i class="fas fa-plus"></i> Ticket
      </Button>
    </template>

    <div v-if="selectedVue === 'list-tickets'">
      <Panel>
        <div class="uk-text-center">
          <h4 class="uk-margin-remove">Found an issue not listed below?</h4>
          <h4 class="uk-margin-remove">Thought of a new feature that could be added?</h4>
          <h4 class="uk-margin-remove">Let us know by creating a ticket in the top right.</h4>
          <h4 class="uk-margin-remove">Thank you for your support!</h4>
        </div>
      </Panel>

      <Panel>
        <Tabs :titles="['Requests', 'Support']">
          <template #tab-1>
            <div class="pills">
              <div>
                <span class="pill" :class="sectionFilter.includes(section) ? 'active-pill' : ''"
                      v-for="(section, index) in sections" :key="section"
                      @click="filter(section, 'section')">
                  {{ index }}
                </span>
              </div>
              <div>
                <span class="pill" :class="statusFilter === 'resolved' ? 'active-pill' : ''" @click="filter('resolved', 'status')">Resolved</span>
                <span class="pill" :class="statusFilter === 'live' ? 'active-pill' : ''" @click="filter('live', 'status')">Live</span>
              </div>
              <div>
                <span class="pill" :class="typeFilter.includes(type) ? 'active-pill' : ''"
                      v-for="(type, index) in types" :key="type"
                      @click="filter(type, 'type')">
                  {{ index }}
                </span>
              </div>
            </div>
            <div class="uk-child-width-1-4@l uk-child-width-1-2@m uk-grid-row-small" uk-grid
                 uk-height-match="target: > div > div">
              <div v-for="ticket in tickets" :key="ticket.id">
                <Card
                    v-if="ticket.deleted_at === null"
                    :label="ticket.description"
                    :infoMessage="ticket.section + ' - ' + ticket.type"
                    :info="`<i class='fa-solid fa-thumbs-up'></i> ${ticket.votes.length}`"
                    @click="getSelectedIssue(ticket)"
                />
                <Card
                    v-else
                    :label="ticket.description"
                    :infoMessage="ticket.section + ' - ' + ticket.type"
                    warningMessage="Resolved"
                    :active="false"
                    @click="getSelectedIssue(ticket)"
                />
              </div>
            </div>
          </template>
          <template #tab-2>
            <div class="uk-child-width-1-4@l uk-child-width-1-2@m uk-grid-row-small" uk-grid
                 uk-height-match="target: > div > div">
              <div v-for="ticket in supports" :key="ticket.id">
                <Card
                    :label="ticket.description"
                    :infoMessage="ticket.section + ' - ' + ticket.type"
                    :info="`${ticket.votes.length} <i class='fa-solid fa-thumbs-up'></i>`"
                    @click="getSelectedIssue(ticket)"
                />
              </div>
              <div v-for="ticket in resolvedSupports" :key="ticket.id">
                <Card
                    :label="ticket.description"
                    :infoMessage="ticket.section + ' - ' + ticket.type"
                    warningMessage="Resolved"
                    :active="false"
                    @click="getSelectedIssue(ticket)"
                />
              </div>
            </div>
          </template>
        </Tabs>
      </Panel>
    </div>

    <div v-if="selectedVue === 'create-ticket'">
      <Panel>
        <CreateTicket :types="types" :sections="sections" @submittedForm="submittedForm" />
      </Panel>
    </div>

    <div v-if="selectedVue === 'view-ticket'">
      <ViewTicket v-if="activeIssue" :ticket="activeIssue" @updateIssues="back" />
    </div>
  </Authenticated>
</template>

<style scoped>
.uk-card {
  box-shadow: none;
  transform: none;
  text-align: left;
}

.pills {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.pill {
  border: 2px solid var(--primaryColour);
  border-radius: 10px;
  padding: 0 12px;
  margin: 0 6px;
  font-size: 14px;
  cursor: pointer;
}
.active-pill {
  color: white;
  background-color: var(--primaryColour);
}
</style>
