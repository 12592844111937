<script setup>
import { Input, Modal } from '@/components/index.js'
import { ref, defineEmits } from 'vue'

defineEmits(['closeModal'])

const display = ref('')

const result = () => {
  display.value = window.Function(`'use strict'; return (${display.value})`)()
}
</script>

<template>
  <Modal
      title="Calculator"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div>
        <Input v-model="display" disabled />
      </div>
      <div class="grid-4 keypad">
        <div class="clear" @click="display = ''">
          C
        </div>
        <div class="operator" @click="display += '*'">
          X
        </div>
        <div class="operator" @click="display += '/'">
          /
        </div>
        <div class="operator" @click="display += '-'">
          -
        </div>
        <div @click="display += '7'">
          7
        </div>
        <div @click="display += '8'">
          8
        </div>
        <div @click="display += '9'">
          9
        </div>
        <div class="operator" @click="display += '+'">
          +
        </div>
        <div @click="display += '4'">
          4
        </div>
        <div @click="display += '5'">
          5
        </div>
        <div @click="display += '6'">
          6
        </div>
        <div class="operator" @click="display += '/100'">
          %
        </div>
        <div @click="display += '1'">
          1
        </div>
        <div @click="display += '2'">
          2
        </div>
        <div @click="display += '3'">
          3
        </div>
        <div class="operator" @click="result()">
          =
        </div>
        <div @click="display += '0'">
          0
        </div>
        <div @click="display += '.'">
          .
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.keypad {
  margin-top: 10px;
}

.keypad > div {
  background-color: var(--secondaryColour);
  color: var(--bodyColour);
  display: flex;
  height: 75px;
  font-size: 20px;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  cursor: pointer;
}

.clear {
  background-color: var(--dangerColour)!important;
}
.operator {
  background-color: var(--infoColour)!important;
}
</style>
