<template>
  <div class="tooltip">
    <slot />
    <div class="tooltip-value" :class="this.position">
      {{ this.value }}
      <div class="tip" :class="'tip-'+this.position" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    value: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  setup () {

  }
}
</script>

<style scoped>
p {
  margin: 0;
}
.tooltip {
  position: relative;
  cursor: default;
}

.tooltip .tooltip-value {
  visibility: hidden;
  width: fit-content;
  min-width: 100px;
  max-width: 250px;
  background-color: var(--tertiaryColour);
  color: var(--bodyColour);
  border: 2px solid var(--primaryColour);
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  font-size: 14px;
}

.tooltip:hover .tooltip-value {
  visibility: visible;
}

.tip {
  background-color: var(--tertiaryColour);
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translateX(50%) rotate(45deg);
}
.tip-top {
  top: calc(100% - 5px);
  left: calc(50% - 10px);
}
.tip-left {
  top: 50%;
  right: calc(-5px);
  transform: translateY(-50%) rotate(45deg);
}
.tip-right {
  top: 50%;
  left: calc(-5px);
  transform: translateY(-50%) rotate(45deg);
}
.tip-bottom {
  bottom: calc(100% - 5px);
  left: calc(50% - 10px);
}

.top {
  bottom: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
}
.left {
  bottom: 0;
  right: calc(100% + 5px);
  transform: translateY(25%);
}
.right {
  bottom: 0;
  left: calc(100% + 5px);
  transform: translateY(25%);
}
.bottom {
  top: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
}
</style>
