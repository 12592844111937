<script setup>
import Modal from '../../components/section/system/SystemModal.vue'
import Datepicker from '@vuepic/vue-datepicker'
import SolButton from '../../components/SolButton.vue'
import { ref } from 'vue'

const destination = ref('single')

</script>

<template>
  <Modal modal_id="new-travel" title="New Travel Plan" :sidebar="false">
    <div>
      <form>
        <input type="text" class="uk-input uk-margin-small-bottom uk-border-rounded" placeholder="Traveling Title">
        <div class="uk-margin uk-grid-small">
          <label>
            <input class="uk-radio" type="radio" value="single" v-model="destination">
            Single
          </label>
          <label>
            <input class="uk-radio" type="radio" value="multiple" v-model="destination">
            Multiple
          </label>
          <label class="uk-margin-left">
            Destination
          </label>
        </div>
        <Datepicker v-if="destination === 'single'"
            v-model="date"
            format="dd/MM/yyyy"
            range
            multiCalendars
            :enableTimePicker="false"
            placeholder="Start/End Date"
            showNowButton
            class="uk-margin-small-bottom"
        />
        <SolButton class="btn-success uk-margin-top" action="">
          Create
        </SolButton>
      </form>
    </div>
  </Modal>
</template>

<style scoped>

</style>
