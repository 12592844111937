import axios from 'axios'

class NoteServices {
  async getNotes (userId) {
    return await axios.get('/get-notes/' + userId)
  }

  async getNote (noteId, userId) {
    return await axios.get('/get-note/' + noteId + '/' + userId)
  }

  async updateNote (noteId, data) {
    return await axios.post('/update-note/' + noteId, data)
  }

  async updateNoteDetails (noteId, data) {
    return await axios.post('/update-note-details/' + noteId, data)
  }

  async submitNote (userId, data) {
    return await axios.post('/create-note/' + userId, data)
  }

  async deleteNote (noteId) {
    return await axios.delete('/delete-note/' + noteId)
  }
}

export default NoteServices
