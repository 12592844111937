<template>
  <div v-if="icon" class="has-icon" :class="dark ? 'dark' : 'light'">
    <input :type="type" :step="step" class="uk-input uk-border-rounded" :class="small ? 'small' : ''" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
    <span class="icon" :class="flip ? 'right' : 'left'"><slot /></span>
  </div>
  <input v-else :type="type" :step="step" :min="min" :max="max" class="uk-input uk-border-rounded" :class="small ? 'small' : ''" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
</template>

<script>
export default {
  name: 'SolInput',
  props: {
    modelValue: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'text'
    },
    step: {
      type: String
    },
    icon: {
      type: Boolean,
      default: false
    },
    flip: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      required: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style scoped>
input:focus {
  border-color: var(--primaryColour);
}
.has-icon {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.dark > input {
  background-color: var(--secondaryColour);
  color: var(--bodyColour);
}
.dark > span {
  color: var(--bodyColour);
}
.light > input {
  background-color: white;
  color: #666666;
}
.light > span {
  color: #666666;
}
.small {
  height: 30px;
}
.left {
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
}
.right {
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
}
</style>
