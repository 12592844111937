<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import Button from '../../components/SolButton.vue'
import Timeline from '../../components/TimelineComp.vue'

const travels = {
  1: {
    title: 'Cross Country America',
    quantity: 2,
    completed: true,
    destinations: {
      1: {
        title: 'New York',
        from: '01/08/2022',
        to: '05/08/2022'
      },
      2: {
        title: 'Washington',
        from: '05/08/2022',
        to: '10/08/2022'
      },
      3: {
        title: 'Florida',
        from: '10/08/2022',
        to: '20/08/2022'
      }
    },
    events: {
      1: {
        title: 'Empire State Building',
        date: '03/08/2022',
        price: 30
      },
      2: {
        title: 'The White House',
        date: '09/08/2022',
        price: 10
      },
      3: {
        title: 'Universal Studios',
        date: '14/07/2022',
        price: 45
      },
      4: {
        title: 'Disney Land',
        date: '17/08/2022',
        price: 58.99
      }
    }
  }
}

const plan = travels[1]
</script>

<template>
  <Authenticated>
    <template #header>
      <div class="uk-clearfix">
        <div class="uk-float-left uk-margin-small-top">
          <router-link to="/travels" class="uk-link-text">
            <i class="fa-solid fa-chevron-left"></i>
          </router-link>
        </div>
        <Button v-if="!plan.completed"
            action="success"
            class="uk-float-right"
            @click="confirm('Are the plans complete?')">
          <i class="fas fa-check"></i> Completed
        </Button>
      </div>
    </template>

    <div class="uk-grid uk-child-width-1-2@l" uk-grid>
      <div>
        <Timeline />
      </div>
      <div>
        <div class="uk-card uk-card-default uk-padding-small uk-border-rounded">
          <div class="uk-card-title uk-margin-small-bottom">General</div>
          <div class="uk-clearfix">
            <div class="uk-float-left uk-text-bold">Title:</div>
            <div class="uk-float-right">Cross Country</div>
          </div>
          <hr>
          <div class="uk-clearfix">
            <div class="uk-float-left uk-text-bold">Start Date:</div>
            <div class="uk-float-right">{{ plan.destinations[1].from }}</div>
          </div>
          <hr>
          <div class="uk-clearfix">
            <div class="uk-float-left uk-text-bold">End Date:</div>
            <div class="uk-float-right">{{ plan.destinations[Object.keys(plan.destinations).length].to }}</div>
          </div>
          <hr>
        </div>
      </div>
    </div>

  </Authenticated>
</template>

<style scoped>
.uk-card {
  box-shadow: none;
  transform: none;
  text-align: left;
}
</style>
