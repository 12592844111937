<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  open: {
    type: Boolean,
    default: false
  }
})

const isOpen = ref(props.open)

</script>

<template>
  <div class="accordion">
    <a class="accordion-header" @click="isOpen = !isOpen">
      <span class="accordion-title">{{ title }}</span>
      <span class="accordion-icon">
        <i v-if="!isOpen" class="fa-solid fa-plus"></i>
        <i v-else class="fa-solid fa-minus"></i>
      </span>
    </a>
    <div v-show="isOpen" class="accordion-content">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.accordion {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 8px 18px 18px rgba(0, 0, 0, .1);
  background-color: white;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
.accordion-header {
  display: block;
  cursor: pointer;
  padding: 15px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
  transition: 0.4s;
  text-decoration: none;
  line-height: 1.4;
  color: #333;
  overflow: hidden;
  user-select: none;
}

.accordion-header > span:first-child {
  float: left;
}
.accordion-header > span:last-child {
  float: right;
}

.accordion-content {
  padding: 0 15px 15px 15px;
}
</style>
