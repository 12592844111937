<script setup>
import { Button, Input, Checkbox, Toggle, Modal, ToolTip, Tabs } from '../../../components/index'
import WishlistServices from '@/utils/services/wishlistServices'
import Datepicker from '@vuepic/vue-datepicker'
import { store } from '@/store'
import { defineEmits, ref } from 'vue'

const emit = defineEmits(['closeModal', 'createdList'])

const forWho = ref(1)
const preset = ref(true)
const presets = ref({
  christmas: false,
  birthday: false
})
const name = ref(null)
const endDate = ref(null)
const recur = ref(true)
const selectedFriend = ref(null)
const errors = ref([])
const service = new WishlistServices()

const reset = () => {
  name.value = null
  selectedFriend.value = null
  endDate.value = null
  preset.value = true
  recur.value = true
  presets.value = {
    christmas: false,
    birthday: false
  }
  errors.value = []
}

const changeFor = (view) => {
  forWho.value = view
  reset()
}

const submit = () => {
  errors.value = []
  if (forWho.value === 1) {
    if (preset.value) {
      if (!presets.value.christmas && !presets.value.birthday) {
        errors.value.presets = 'A preset is required'
      }
    } else {
      if (name.value === null) {
        errors.value.name = 'A list name is required'
      }
      if (endDate.value === null) {
        errors.value.endDate = 'An end date is required'
      }
    }
  } else {
    if (selectedFriend.value === null) {
      errors.value.selectedFriend = 'Name for the list is required'
    }
    if (name.value === null) {
      errors.value.name = 'A list name is required'
    }
    if (endDate.value === null) {
      errors.value.endDate = 'An end date is required'
    }
  }

  if (errors.value.length === 0) {
    const data = {
      for: forWho.value,
      target: selectedFriend.value,
      name: name.value,
      endDate: endDate.value,
      preset: forWho.value === 2 ? false : preset.value,
      recur: forWho.value === 2 ? false : recur.value,
      presets: presets.value
    }

    service.createList(store.user.id, data)
      .then((response) => {
        emit('createdList', response.data)
        emit('closeModal')
      })
  }
}
</script>

<template>
  <Modal
      title="Create New List"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <Tabs :titles="['For You', 'For A Friend']" @change="changeFor">
        <template #tab-1>
          <div class="element-section">
            Create preset?
            <Toggle @click="preset = !preset" v-model="preset" />
          </div>
          <div class="element-section" v-if="preset">
            Presets <span class="text-danger">{{ errors['presets'] }}</span>
            <div class="grid">
              <div v-for="(set, index) in presets" :key="index">
                <Checkbox :fieldId="index" v-model:checked="presets[index]" :label="index" >
                  List
                </Checkbox>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="element-section">
              List name <span class="text-danger">{{ errors['name'] }}</span>
              <Input v-model="name" type="text" placeholder="List name"/>
            </div>
            <div class="element-section">
              List end date <span class="text-danger">{{ errors['endDate'] }}</span>
              <Datepicker
                  v-model="endDate"
                  format="dd/MM/yyyy"
                  :enableTimePicker="false"
                  placeholder="Select End Date"
                  :flow="['year', 'month', 'day']"
                  :min-date="new Date()"
              />
            </div>
            <div class="element-section">
              <div class="helper">
                Recur
                <ToolTip value="Once the lists has expired it will recur to the next year.">
                  <i class="fa-solid fa-circle-question btn-icon-info"></i>
                </ToolTip>
              </div>
              <Toggle @click="recur = !recur" v-model="recur" />
            </div>
          </div>
        </template>
        <template #tab-2>
          <div class="element-section">
            Who is the list for <span class="text-danger">{{ errors['selectedFriend'] }}</span>
            <Input v-model="selectedFriend" text="text" placeholder="First name of your friend" />
          </div>
          <div class="element-section">
            Event name <span class="text-danger">{{ errors['name'] }}</span>
            <Input v-model="name" type="text" placeholder="Event Name" />
          </div>
          <div class="element-section">
            List end date <span class="text-danger">{{ errors['endDate'] }}</span>
            <Datepicker
                v-model="endDate"
                format="dd/MM/yyyy"
                :enableTimePicker="false"
                placeholder="Select End Date"
                :flow="['year', 'month', 'day']"
                :min-date="new Date()"
            />
          </div>
        </template>
      </Tabs>

      <hr/>

      <Button action="success" @click="submit">
        Create
      </Button>
    </template>
  </Modal>
</template>

<style scoped>
hr {
  margin: 10px 0;
}
.switch {
  display: flex;
  width: 100%;
  list-style: none;
  justify-content: space-around;
  padding: 0;
  border-bottom: 1px solid var(--primaryColour);
  gap: 15px;
}
.switch > li {
  padding: 10px 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.switch-active {
  position: relative;
  background-color: var(--primaryColour);
  color: var(--bodyColour);
  border-radius: 10px 10px 0 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  max-height: 160px;
  overflow-y: scroll;
}
</style>
