<script setup>
import { Modal, ToolTip, Input, Button, Dropdown, Alert } from '@/components/index.js'
import WishlistServices from '@/utils/services/wishlistServices'
import moment from 'moment'
import { store } from '@/store'
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const props = defineProps(['list', 'friends', 'templateItems'])
defineEmits(['confirmModal'])
const isOwner = ref(false)
const tempObj = ref(null)
const quantity = ref(1)
const name = ref('')
const link = ref('')
const price = ref('0.00')
const user = store.user
const editRow = ref({ id: 0 })
const saveRow = ref(false)
const howMany = ref(1)
const items = ref()
const service = new WishlistServices()

const checkInvite = (friend) => {
  let check = false
  props.list.wishlist_users.forEach(element => {
    if (element.id === friend) {
      check = true
    }
  })
  return check
}

const reset = () => {
  quantity.value = 1
  name.value = ''
  link.value = ''
  price.value = '0.00'
}

const itemState = (bought_by, reserved_by) => {
  if (bought_by) {
    return 'list-item-bought'
  } else if (reserved_by) {
    return 'list-item-reserved'
  } else {
    return ''
  }
}

const selectTemplateItems = (item) => {
  name.value = item.name
  link.value = item.link
  price.value = item.price
}

const addItem = (id) => {
  if (quantity.value > 0 && name.value && price.value) {
    service.addItem(id, {
      quantity: quantity.value,
      name: name.value,
      link: link.value,
      price: price.value
    }).then((response) => {
      // eslint-disable-next-line vue/no-mutating-props
      items.value.push(response.data)
      const templates = document.getElementsByName('templateItems')
      for (let i = 0; i < templates.length; i++) {
        templates[i].checked = false
      }
      reset()
    })
  }
}

const reserve = (id) => {
  service.reserveItem(id, user.id)
  items.value.forEach(element => {
    if (element.id === id) {
      if (element.reserved_by === null) {
        element.reserved_by = user.id
      } else {
        element.reserved_by = null
      }
    }
  })
}

const bought = (id, quantity = null) => {
  const data = {
    quantity
  }
  service.boughtItem(id, user.id, data)
    .then((response) => {
      reformatItems(response.data.items)
    })
}

const editItem = (id) => {
  if (editRow.value.id === 0) {
    items.value.forEach(element => {
      if (element.id === id) {
        Object.assign(editRow.value, element)
      }
    })
  } else {
    editRow.value = { id: 0 }
    saveRow.value = false
  }
}

const deleteItem = (id) => {
  service.deleteItem(id)
    .then(() => {
      const temp = []
      items.value.forEach(x => {
        if (x.id !== id) {
          temp.push(x)
        }
      })
      items.value = temp
    })
}

const updateItem = (row) => {
  items.value.forEach(element => {
    if (element.id === row.id) {
      tempObj.value = element
    }
  })

  if (
    tempObj.value.quantity === row.quantity &&
      tempObj.value.name === row.name &&
      tempObj.value.link === row.link &&
      tempObj.value.price === row.price
  ) {
    editRow.value = { id: 0 }
    saveRow.value = false
  }

  service.updateItem(row.id, {
    quantity: row.quantity,
    name: row.name,
    link: row.link,
    price: row.price
  }).then((response) => {
    items.value.forEach(element => {
      if (element.id === row.id) {
        element.quantity = response.data.quantity
        element.name = response.data.name
        element.link = response.data.link
        element.price = response.data.price
      }
    })
    editRow.value = { id: 0 }
    saveRow.value = false
  })
}

const submitReserveTime = (list) => {
  service.submitReserveTime(list.id, {
    timeLimit: list.reserve_time_limit
  })
}

const submitInvite = (list, friend) => {
  service.submitInvite(list, friend)
}

const reformatItems = (newItems) => {
  if (isOwner.value) {
    items.value = newItems
  } else {
    const listItems = []
    newItems.forEach(i => {
      if (i.how_many.length >= 1) {
        const temp = []
        let quantity = 0
        i.how_many.forEach((y, index) => {
          temp.push({
            id: i.id + '-' + index,
            wishlist_id: i.wishlist_id,
            bought_by: y.id,
            reserved_by: null,
            quantity: y.quantity,
            name: i.name,
            link: i.link,
            price: i.price,
            reserved_at: null
          })
          quantity += y.quantity
        })
        i.quantity = i.quantity - quantity
        listItems.push(i)
        temp.forEach(x => {
          listItems.push(x)
        })
      } else {
        listItems.push(i)
      }
    })
    items.value = listItems
  }
}

const truncate = (value) => {
  if (value !== null) {
    if (value.length > 30) {
      return value.substring(0, 30) + '...'
    }
  }

  return value
}

onMounted(() => {
  isOwner.value = props.list.created_by === user.id

  service.getItems(props.list.id)
    .then((response) => {
      reformatItems(response.data.items)
    })
})
</script>

<template>
  <Modal
      :title="list.title"
      :sidebar="isOwner"
      big
  >
    <template #sidebar>
      <div v-if="list.status === 'draft'" class="element-section">
        <div class="pill-default">{{ list.status }}</div>
      </div>
      <div class="element-section">
        End date:
        <div>{{ moment(list.end_date).format('Do MMMM YYYY') }}</div>
      </div>
      <div v-if="list.status === 'active'" class="element-section">
        Invite friends:
        <div class="friend-list" v-if="friends">
          <div v-for="friend in friends" :key="friend.id">
            <input
                type="checkbox"
                @click="submitInvite(list.id, friend.id)"
                :checked="checkInvite(friend.id)">
            <label>{{ friend.first_name }} {{ friend.last_name}}</label>
          </div>
        </div>
      </div>
      <div v-else-if="templateItems.length > 0" class="element-section">
        Template Items:
        <div class="friend-list" v-if="friends">
          <div v-for="item in templateItems" :key="item.id">
            <input name="templateItems" type="radio" @click="selectTemplateItems(item)">
            <label>{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="element-section">
        <div class="reserve-header">
          <ToolTip v-if="list.status === 'draft'" value="Reserve time limit is how long an item can be reserved for before being reset.">
            <i class="fa-solid fa-circle-question btn-icon-info"></i>
          </ToolTip>
          Reserve time limit:
        </div>
        <div>
          <Input
              v-if="list.status === 'draft'"
              v-model="$props.list.reserve_time_limit"
              type="number" icon flip dark small
              @change="submitReserveTime($props.list)"
          >
            Days
          </Input>
          <span v-else>{{ $props.list.reserve_time_limit }} Days</span>
        </div>
      </div>
      <div class="sidebar-controls">
        <Button v-if="list.status === 'draft'" action="success" @click="$emit('confirmModal', false)">Activate List</Button>
        <Button action="danger" @click="$emit('confirmModal', true)">Delete List</Button>
      </div>
    </template>
    <template #content>
      <Alert v-if="isOwner && list.status === 'active'" text="You can add items but cannot edit or delete them. Make sure you enter them correctly!" type="info" />
      <table class="items-table">
        <thead class="items-header">
          <th class="column-tiny centre" v-if="!isOwner">
            <ToolTip value="Bought" position="bottom">
              B
            </ToolTip>
          </th>
          <th class="column-tiny centre" v-if="!isOwner">
            <ToolTip value="Reserve" position="bottom">
              R
            </ToolTip>
          </th>
          <th class="column-small">Quantity</th>
          <th>Name</th>
          <th>Link</th>
          <th class="column-medium">Price</th>
          <th class="column-small" v-if="isOwner"></th>
        </thead>
        <tbody>
          <tr class="item" v-for="item in items" :key="item.id" :class="!isOwner ? itemState(item.bought_by, item.reserved_by) : ''">
            <td class="centre" v-if="!isOwner">
              <Dropdown v-if="!item.bought_by && item.quantity > 1" right>
                <template #display>
                  <span class="bought-item-dropdown"></span>
                </template>
                <template #content>
                  <div class="item-bought-action">
                    <div class="element-section">
                      How Many
                    </div>
                    <div class="element-section">
                      <Input v-model="howMany" :min="1" :max="item.quantity" small type="number" />
                    </div>
                    <Button action="success" @click="bought(item.id, howMany)">Bought</Button>
                  </div>
                </template>
              </Dropdown>

              <input v-else type="checkbox"
                     :checked="item.bought_by"
                     :disabled="
                     (item.bought_by !== user.id && item.bought_by !== null) ||
                     (item.reserved_by !== user.id && item.reserved_by !== null)"
                     @click="bought(item.id)"
              />
            </td>
            <td class="centre" v-if="!isOwner">
              <input type="checkbox"
                     :checked="item.reserved_by"
                     :disabled="
                     (item.reserved_by !== user.id && item.reserved_by !== null) ||
                     (item.bought_by)"
                     @click="reserve(item.id)"
              />
            </td>
            <td>
              <div v-if="editRow.id === item.id">
                <Input v-model="editRow.quantity" small type="number" @input="saveRow = true" />
              </div>
              <div v-else>
                {{ item.quantity }}
              </div>
            </td>
            <td>
              <div v-if="editRow.id === item.id">
                <Input v-model="editRow.name" small @input="saveRow = true" />
              </div>
              <div v-else>
                <span v-if="item.name.length > 30">
                  <ToolTip :value="item.name">
                    {{ truncate(item.name) }}
                  </ToolTip>
                </span>
                <span v-else>
                  {{ item.name }}
                </span>
              </div>
            </td>
            <td>
              <div v-if="editRow.id === item.id">
                <Input v-model="editRow.link" small placeholder="(optional)" @input="saveRow = true" />
              </div>
              <div v-else>
                <a :href="item.link" target="_blank">{{ truncate(item.link) }}</a>
              </div>
            </td>
            <td class="price-column">
              <div v-if="editRow.id === item.id">
                <Input
                    v-model="editRow.price"
                    type="number"
                    icon small
                    :step="0.01"
                    @input="saveRow = true"
                >£</Input>
                <div class="form-info">
                  <span class="form-description">(Price of one)</span>
                </div>
              </div>
              <div v-else class="price-static">
                <div v-if="item.quantity === 1">
                  {{ new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(item.price) }}
                </div>
                <div v-else-if="item.quantity > 1">
                  {{ item.quantity }}x {{ new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(item.price * item.quantity) }}
                  <div class="form-info">
                    <span class="form-description">1x {{ new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(item.price) }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td v-if="list.status === 'draft'" class="item-action">
              <ToolTip value="Edit">
                <a v-if="editRow.id === 0" class="btn btn-icon-info" @click="editItem(item.id)">
                  <i class="fa-regular fa-pen-to-square"></i>
                </a>
              </ToolTip>

              <ToolTip value="Remove">
                <a v-if="editRow.id === 0"
                   class="btn btn-icon-danger" @click="deleteItem(item.id)">
                  <i class="fa-solid fa-trash"></i>
                </a>
              </ToolTip>

              <ToolTip value="Cancel">
                <a v-if="editRow.id === item.id && saveRow === false" class="btn btn-icon-danger" @click="editItem(item.id)">
                  <i class="fa-solid fa-xmark"></i>
                </a>
              </ToolTip>

              <ToolTip value="Save">
                <a v-if="editRow.id === item.id && saveRow === true"
                   class="btn btn-icon-success" @click="updateItem(editRow)">
                  <i class="fa-solid fa-check"></i>
                </a>
              </ToolTip>
            </td>
          </tr>
          <tr class="item" v-if="isOwner">
            <td v-if="!isOwner"></td>
            <td v-if="!isOwner"></td>
            <td><Input v-model="quantity" small type="number" /></td>
            <td><Input v-model="name" small /></td>
            <td><Input v-model="link" small placeholder="(optional)" /></td>
            <td>
              <Input
                  v-model="price"
                  type="number"
                  icon small
                  :step="0.01"
                  id="price"
              >£</Input>
              <div class="form-info">
                <span class="form-description">(Price of one)</span>
              </div>
            </td>
            <td>
              <ToolTip value="Add">
                <a class="btn btn-icon-success" @click="addItem(list.id)"><i class="fas fa-plus"></i></a>
              </ToolTip>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Modal>
</template>

<style scoped>
.reserve-header {
  display: flex;
  gap: 4px;
}

.sidebar-controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.items-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  position: relative;
}
.items-header > th {
  height: 50px;
  font-weight: normal !important;
}
.item {
  border-top: 1px solid #CCCCCC !important;
  height: 65px;
}
.column-tiny {
  width: 50px;
}
.column-small {
  width: 10%;
}
.column-medium {
  width: 20%;
  min-width: 100px;
}
td {
  padding: 0 5px;
}

.form-info {
  position: relative;
}
.form-description {
  position: absolute;
  bottom: -16px;
  left: 2px;
  font-size: 13px;
}
.item-bought-action {
  color: white;
}
.item-action {
  display: flex;
  width: 100px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 22%;
}

.bought-item-dropdown {
  display: inline-block;
  height: 14.4px!important;
  width: 14.4px!important;
  background-color: transparent;
  margin: -4px 3px 0.5px 4px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.btn {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.list-item-reserved {
  background-color: rgba(30, 135, 240, .2);
}
.list-item-bought {
  background-color: rgba(92, 184, 92, .4);
}
.price-static {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
</style>
