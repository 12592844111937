<script setup>
import { Panel, Toggle, Button } from '../../../components/index'
import { ref } from 'vue'
import { store } from '@/store'
import SettingServices from '@/utils/services/settingServices'
import router from '@/router'

const service = new SettingServices()
const form = ref(store.user.preference)
const wishlist = ref({
  listView: !!(form.value && form.value.wishlist?.listView),
  singleView: !!(form.value && form.value.wishlist?.singleView)
})

const note = ref({
  listView: !!(form.value && form.value.note?.listView)
})

const project = ref({
  listView: !!(form.value && form.value.project?.listView)
})

const updatePreferences = () => {
  form.value = {
    wishlist: wishlist.value,
    note: note.value,
    project: project.value
  }

  service.updatePreferences(store.user.id, form.value)
    .then((response) => {
      const user = JSON.parse(localStorage.getItem('user'))
      user.preference = response.data
      localStorage.setItem('user', JSON.stringify(user))
      router.go(router.currentRoute)
    })
}

</script>

<template>
  <div>
    <div class="grid-3">
      <Panel title="Notes">
        <p>The way you view all Notes.</p>
        <div class="option">
          <span>Cards</span>
          <Toggle @click="note.listView = !note.listView" v-model="note.listView" />
          <span>List</span>
        </div>
      </Panel>
      <Panel title="Projects">
        <p>The way you view all Projects.</p>
        <div class="option">
          <span>Cards</span>
          <Toggle @click="project.listView = !project.listView" v-model="project.listView" />
          <span>List</span>
        </div>
      </Panel>
      <Panel title="Wishlists">
        <p>The way you view all Wishlists.</p>
        <div class="option">
          <span>Cards</span>
          <Toggle @click="wishlist.listView = !wishlist.listView" v-model="wishlist.listView" />
          <span>List</span>
        </div>
        <p>The way you view a single Wishlist.</p>
        <div class="option">
          <span>Overlay</span>
          <Toggle @click="wishlist.singleView = !wishlist.singleView" v-model="wishlist.singleView" />
          <span>Page</span>
        </div>
      </Panel>
    </div>
    <div class="save">
      <Button dark action="success" @click.prevent="updatePreferences">Save</Button>
    </div>
  </div>
</template>

<style scoped>
.option {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 0 10px 10px 10px;
}
.option > span {
  width: 70px;
  text-align: center;
}

p {
  text-align: center;
  margin: 10px 0;
}

.save {
  display: flex;
  float: right;
}

@media only screen and (max-width: 1166px) {
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    height: fit-content;
  }
}
</style>
