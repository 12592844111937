<script setup>
import { ToolTip, Button } from '@/components/index'
import CalendarServices from '@/utils/services/calendarServices'
import { defineProps, defineEmits } from 'vue'
import moment from 'moment'
import { store } from '@/store'
import router from '@/router'

defineProps(['currentEvent'])
defineEmits(['editModal'])
const user = store.user
const service = new CalendarServices()

const getUserInEvent = (users) => {
  let member = null
  users.forEach(x => {
    if (x.id === user.id) {
      member = x
    }
  })
  return member
}

const dayDifference = (day1, day2) => {
  const start = moment(day1)
  const end = day2.startOf('day')
  const difference = start.diff(end, 'days')
  let amount = ''
  let text = ''

  if (difference === 0) {
    amount = 'Today'
    text = ''
  } else if (difference > 0) {
    amount = difference
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Left'
  } else {
    amount = Math.abs(difference)
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Ago'
  }

  return {
    amount,
    text
  }
}

const changeDecision = (event, decision) => {
  service.changeDecision(event, user.id, decision)
    .then(() => {
      router.go()
    })
}

const deleteEvent = (id) => {
  service.deleteEvent(id, user.id)
    .then(() => {
      router.go()
    })
}

</script>

<template>
  <div>
    <div class="current-event-info" v-if="currentEvent.description !== null">
      {{ currentEvent.description }}
    </div>
    <div class="current-event-info">
      <ul class="members">
        <li v-for="member in currentEvent.users" :key="member.id" class="member">
            <span>
              {{ member.id === user.id ? 'Me' : member.first_name + ' ' + member.last_name }}
            </span>
          <div>
            <span class="options" v-if="currentEvent.user_id !== member.id && member.id === user.id">
              <ToolTip value="Going">
                <a v-if="member.pivot.decision !== 'yes'" class="text-success" @click="changeDecision(currentEvent.id, 'yes')">
                    <i class="fas fa-check"></i>
                </a>
              </ToolTip>
              <ToolTip value="Maybe">
                <a v-if="member.pivot.decision !== 'maybe'" class="text-warning" @click="changeDecision(currentEvent.id, 'maybe')">
                    <i class="fas fa-minus"></i>
                </a>
              </ToolTip>
              <ToolTip value="Not Going">
                <a v-if="member.pivot.decision !== 'no'" class="text-danger" @click="changeDecision(currentEvent.id, 'no')">
                    <i class="fas fa-times"></i>
                </a>
              </ToolTip>
            </span>
            <span v-if="currentEvent.user_id !== member.id && member.id !== user.id">
                  <ToolTip value="Pending" position="left">
                    <span v-if="member.pivot.decision === null" class="text-info">
                      <i class="fa-solid fa-ellipsis"></i>
                    </span>
                  </ToolTip>
                  <ToolTip value="Going" position="left">
                    <span v-if="member.pivot.decision === 'yes'" class="text-success">
                      <i class="fa-solid fa-check"></i>
                    </span>
                  </ToolTip>
                  <ToolTip value="Maybe" position="left">
                    <span v-if="member.pivot.decision === 'maybe'" class="text-warning">
                      <i class="fa-solid fa-minus"></i>
                    </span>
                  </ToolTip>
                  <ToolTip value="Not Going" position="left">
                    <span v-if="member.pivot.decision === 'no'" class="text-danger">
                      <i class="fa-solid fa-times"></i>
                    </span>
                  </ToolTip>
                </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="current-event-info">
      <p class="days-count">
        {{ dayDifference(currentEvent.start_date, moment()).amount }}
      </p>
      <p class="days-text">
        {{ dayDifference(currentEvent.start_date, moment()).text }} <br v-if="dayDifference(currentEvent.start_date, moment()).text" />
        {{ moment(currentEvent.start_date).format('Do MMMM YYYY') }}
      </p>
    </div>
    <div class="current-event-info" v-if="getUserInEvent(currentEvent.users).pivot.decision !== null">
      You
      <span class="text-success" v-if="getUserInEvent(currentEvent.users).pivot.decision === 'yes' || currentEvent.user_id === user.id">are going</span>
      <span class="text-warning" v-if="getUserInEvent(currentEvent.users).pivot.decision === 'maybe'">might go</span>
      <span class="text-danger" v-if="getUserInEvent(currentEvent.users).pivot.decision === 'no'">are not going</span>
    </div>
    <div v-if="user.id === currentEvent.user_id" class="event-controls">
      <Button action="info" @click="$emit('editModal')">
        <i class="fa-solid fa-pen-to-square"></i> Edit
      </Button>
      <Button action="danger" @click="deleteEvent(currentEvent.id)">
        <i class="fa-solid fa-trash"></i> Delete
      </Button>
    </div>
    <div v-if="user.id !== currentEvent.user_id && getUserInEvent(currentEvent.users).pivot.decision === 'no'">
      <Button action="danger" class="full-width">Remove from Calendar</Button>
    </div>
  </div>
</template>

<style scoped>
.members {
  list-style: none;
  padding: 0;
  margin: 0;
}
.member {
  display: flex;
  justify-content: space-between;
}
.days-count {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.days-text {
  text-align: center;
  margin: 0;
}
.options {
  display: flex;
  gap: 5px;
}
.event-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
