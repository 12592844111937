<script setup>
import { Modal, Input, Button, Textarea } from '@/components/index'
import NoteServices from '@/utils/services/noteServices'
import { store } from '@/store'
import router from '@/router'
import { defineEmits, ref } from 'vue'

defineEmits(['closeModal'])
const title = ref('')
const colour = ref(null)
const description = ref('')
const errors = ref([])
const service = new NoteServices()

const submit = () => {
  errors.value = []

  if (title.value === '') {
    errors.value.title = 'A title is required'
  }
  if (description.value > 144) {
    errors.value.description = 'Description cannot be more that 144 characters'
  }

  if (errors.value.length === 0) {
    const data = {
      title: title.value,
      colour: colour.value,
      description: description.value
    }
    service.submitNote(store.user.id, data)
      .then(() => {
        router.go()
      })
  }
}
</script>

<template>
  <Modal
      title="Create New Note"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div class="element-section">
        Title of note
        <Input v-model="title" placeholder="Title of note" />
      </div>

      <div class="element-section">
        Select colour
        <Input v-model="colour" type="color" />
      </div>

      <div class="element-section">
        Description
        <Textarea v-model="description" :length="144" />
      </div>

      <hr/>
      <Button action="success" @click="submit">
        Create
      </Button>
    </template>
  </Modal>
</template>

<style scoped>

</style>
