<script setup>
import FeedbackServices from '@/utils/services/feedbackServices'
import { Button } from '@/components/index'
import Editor from '@tinymce/tinymce-vue'
import { store } from '@/store'
import { ref, defineProps, defineEmits } from 'vue'

defineProps([
  'sections',
  'types'
])

const emit = defineEmits(['submittedForm'])

const user = store.user
const service = new FeedbackServices()
const descriptionError = ref(false)
const contentError = ref(false)
const form = ref({
  section: null,
  type: null,
  description: null,
  content: null
})

const submitForm = () => {
  if (form.value.description === '' || form.value.description === null) {
    descriptionError.value = true
    return
  }
  if (form.value.content === '' || form.value.content === null) {
    contentError.value = true
    return
  }

  service.createIssue(user.id, form.value)
    .then(() => {
      emit('submittedForm')
    })
    .catch((error) => {
      console.log(error)
    })
}

</script>

<template>
  <div>
    <h4 class="uk-margin-small-top">Create Ticket</h4>
    <hr>
    <div class="uk-margin-small-bottom">
      Section
      <select v-model="form.section" class="uk-select uk-border-rounded">
        <option :value="null" disabled hidden>Select a section</option>
        <option v-for="(section, index) in sections" :key="section" :value="section">{{ index }}</option>
      </select>
    </div>

    <div v-if="form.section" class="uk-margin-small-bottom">
      Ticket Type
      <select v-model="form.type" class="uk-select uk-border-rounded">
        <option :value="null" disabled hidden>Select a type</option>
        <option v-for="(type, index) in types" :key="type" :value="type">{{ index }}</option>
      </select>
    </div>

    <div v-if="form.type">
      <div class="uk-margin-small-bottom">
        Brief Description
        <span class="uk-text-danger">*</span>
        <span class="uk-text-danger" v-if="descriptionError">Description is required</span>
        <textarea v-model="form.description" class="uk-textarea uk-border-rounded"></textarea>
      </div>

      <div class="uk-margin-small-bottom">
        Content
        (<span v-if="form.type === 'fix'">What seems to be the issue, try to explain your steps to recreate the issue.</span>
        <span v-else-if="form.type === 'feature'">Describe the new feature you would like to see.</span>
        <span v-else>Tell us the problem and how you found it</span>)
        <span class="uk-text-danger" v-if="contentError">Content is required</span>
        <editor
            api-key="h8gmy3cnobj2akxw0sts8xfmpka97an30s7zs5mcbh8jbh4t"
            v-model="form.content"
            :init="{
                height: 300,
                menubar: false,
                branding: false,
                plugins: [
                  'advlist','autolink',
                  'lists','link','charmap','searchreplace','visualblocks',
                  'fullscreen','insertdatetime','media','table'
                ],
                toolbar:
                  'undo redo | casechange blocks | bold italic underline | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent'
              }"
        />
      </div>
    </div>

    <div v-if="form.content">
      <Button dark action="success" @click="submitForm">
        Submit
      </Button>
    </div>
  </div>
</template>

<style scoped>

</style>
