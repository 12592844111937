<template>
  <Ribbon @toggle-sidebar="toggleSidebar" :open="this.open" />
  <sidebar @toggle-sidebar="toggleSidebar" :open="this.open" :user="this.user"/>
  <div class="content" :class="[this.open === 'open' ? 'open' : 'close']">
    <div class="page-header">
      <div class="page-title" v-if="$slots.header">
        <slot name="header" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { Ribbon, Sidebar } from '@/components/section/system/index'
import { store } from '@/store'

export default {
  name: 'AuthenticatedLayout',
  components: {
    Ribbon,
    Sidebar
  },

  data () {
    return {
      open: 'open',
      user: store.user
    }
  },

  methods: {
    toggleSidebar () {
      if (this.open === 'open') {
        this.open = 'close'
      } else {
        this.open = 'open'
      }
    }
  }
}
</script>

<style scoped>
.content {
  position: relative;
  min-height: 100%;
  padding: 10px 10px 0 10px;
}

.content.open {
  width: calc(100% - 272px);
  margin: 50PX 0 10px 250px;
  transition: 0.1s;
}
.content.close {
  width: calc(100% - 97px);
  margin: 50px 0 10px 75px;
  transition: 0.1s;
}

.page-header {
  border-radius: 5px;
  margin-bottom: 10px;
}
.page-title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  gap: 5px;
}

@media only screen and (max-width: 600px) {
  .page-title {
    display: flex;
    flex-direction: column;
  }
}
</style>
