<script setup>
import { Modal, Button, Toggle, Input } from '@/components/index'
import WishlistServices from '@/utils/services/wishlistServices'
import Datepicker from '@vuepic/vue-datepicker'
import router from '@/router'
import { store } from '@/store'
import { defineProps, defineEmits, ref } from 'vue'

const props = defineProps(['list', 'lists'])
defineEmits(['closeModal'])

const step = ref('one')
const option = ref(null)
const selectedList = ref(null)
const title = ref(null)
const recur = ref(false)
const endDate = ref(null)
const minDates = ref(new Date())
const service = new WishlistServices()

const submitDelete = (id) => {
  service.deleteList(id)
    .then(() => {
      router.go()
    })
}

const submitForm = () => {
  let data
  if (option.value === 'select') {
    data = {
      option: option.value,
      data: selectedList.value
    }
  } else if (option.value === 'recur') {
    data = {
      option: option.value,
      data: null
    }
  } else {
    data = {
      option: option.value,
      data: {
        title: title.value,
        recur: recur.value,
        endDate: endDate.value
      }
    }
  }

  service.expired(props.list.id, store.user.id, data)
    .then(() => {
      router.go()
    })
    .catch((error) => {
      console.log(error)
    })
}
</script>

<template>
  <Modal
      title="Expired List"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div class="step-one" v-if="step === 'one'">
        <h5><span class="list-name">{{ list.title }}</span> has expired!</h5>
        <p>Would you like to</p>
        <div class="step-one-controls">
          <Button action="danger" @click="submitDelete(list.id)">Delete the list</Button>
          <p>or</p>
          <Button action="info" @click="step = 'two'">Move the items</Button>
        </div>
      </div>

      <div class="step-two" v-else-if="step === 'two'">
        <ul class="options">
          <li>
            <label for="list">
              <input v-model="option" id="list" type="radio" name="options" value="select">
              Select a list to move items to
            </label>
            <ul v-if="option === 'select'">
              <li v-for="xlist in lists" :key="xlist.id">
                <span v-if="xlist.id !== list.id">
                  <input type="radio" name="list" :value="parseInt(xlist.id)" v-model="selectedList">
                  {{ xlist.title }}
                </span>
              </li>
            </ul>
          </li>
          <li v-if="list.recur">
            <label for="list">
              <input v-model="option" id="list" type="radio" name="options" value="recur">
              Next recurrence
            </label>
          </li>
          <li>
            <label for="list">
              <input v-model="option" id="list" type="radio" name="options" value="new">
              Create a new list for items
            </label>
            <div v-if="option === 'new'">
              List name
              <Input v-model="title" type="text" placeholder="List name" />
              Recur
              <Toggle @click="recur = !recur" v-model="recur" />
              Select end date
              <Datepicker
                  v-model="endDate"
                  format="dd/MM/yyyy"
                  :enableTimePicker="false"
                  placeholder="Select End Date"
                  :flow="['year', 'month', 'day']"
                  :min-date="minDates"
              />
            </div>
          </li>
        </ul>
        <div class="step-two-controls">
          <Button action="info" @click="step = 'one'">Back</Button>
          <Button action="success" :disabled="option === null" @click="submitForm">Submit</Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
ul {
  list-style: none;
}
.options {
  list-style: none;
  padding: 0;
  margin: 0;
}
.options > li {
  border-bottom: 1px solid #CCCCCC;
  padding: 10px 0;
}

.step-one {
  text-align: center;
}
.step-one-controls {
  display: flex;
  justify-content: space-evenly;
}
.step-two-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.list-name {
  font-weight: 600;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 15px;
  height: 15px;
  border: 1px solid var(--tertiaryColour);
  border-radius: 50%;
}
</style>
