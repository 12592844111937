import axios from 'axios'

class FeedbackServices {
  async getIssues (userId, query = null) {
    return await query ? axios.get('/get-issues/' + userId + '/' + query) : axios.get('/get-issues/' + userId)
  }

  async resolveIssue (ticketId) {
    return await axios.post('/resolve-issue/' + ticketId)
  }

  async createIssue (userId, data) {
    return await axios.post('/create-ticket/' + userId, data)
  }

  async sendMessage (ticketId, userId, data) {
    return await axios.post('/send-message-ticket/' + ticketId + '/' + userId, data)
  }

  async sendVote (ticketId, userId) {
    return await axios.post('/send-vote-ticket/' + ticketId + '/' + userId)
  }
}

export default FeedbackServices
