<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Accordion, Button, ToolTip, Card, CardList } from '@/components/index'
import ProjectServices from '@/utils/services/projectServices'
import NewProjectModal from '../project/modals/NewProjectModal'
import Project from './ProjectSinglePage.vue'
import { store } from '@/store'
import moment from 'moment'
import { onMounted, ref } from 'vue'

const isLoading = ref(true)
const friends = ref(null)
const projects = ref(null)
const completed = ref(null)
const newProject = ref(false)
const activeProject = ref()
const selectedVue = ref('list-projects')

const duration = (start, end) => {
  const a = moment(start)
  const b = moment(end)
  const diff = b.diff(a, 'days')
  return diff + (diff > 1 ? ' days' : ' day')
}

const projectAdded = (event) => {
  if (event) {
    newProject.value = false
    projects.value.push(event)
  } else {
    newProject.value = false
  }
}

const isOwner = (project) => {
  let check = false
  project.users.forEach(x => {
    if (x.pivot.owner === true && x.pivot.user_id === store.user.id) {
      check = true
    }
  })
  return check
}

const getOwner = (project) => {
  let name = null
  project.users.forEach(x => {
    if (x.pivot.owner === true) {
      name = x.first_name + ' ' + x.last_name
    }
  })
  return name
}

const viewProject = (projectId) => {
  let found = false
  projects.value.forEach(x => {
    if (x.id === projectId) {
      activeProject.value = x
      found = true
    }
  })

  if (found) {
    selectedVue.value = 'selected-note'
  }
}

onMounted(() => {
  const service = new ProjectServices()

  service.getProjects(store.user.id)
    .then((response) => {
      isLoading.value = false
      projects.value = response.data.projects
      completed.value = response.data.completed
      friends.value = response.data.friends
    })
    .catch(() => {
      isLoading.value = false
    })
})
</script>

<template>
  <Authenticated v-if="selectedVue === 'list-projects'">
    <template #header>
      <span>Projects</span>
      <Button @click="newProject = true" action="dark">
        <i class="fas fa-plus"></i> Project
      </Button>
    </template>

    <Accordion open title="Active Projects">
      <div v-if="isLoading" class="isLoading" uk-spinner></div>
      <div class="grid-4" v-if="!store.user.preference || store.user.preference?.project?.listView === false">
        <Card
            v-for="project in projects" :key="project.id"
            :label="project.title + [!isOwner(project) ? ' by ' + getOwner(project) : '']"
            @click="viewProject(project.id)"
        />
      </div>
      <div v-else>
        <CardList v-for="project in projects" :key="project.id"
                  :label="project.title + [!isOwner(project) ? ' by ' + getOwner(project) : '']"
                  @click="viewProject(project.id)"
        />
      </div>
    </Accordion>

    <Accordion open title="Completed Projects">
      <div v-if="isLoading" class="isLoading" uk-spinner></div>
      <div class="grid-4" v-if="!store.user.preference || store.user.preference?.project?.listView === false">
        <Card
            v-for="project in completed" :key="project.id"
            :label="project.title + [!isOwner(project) ? ' by ' + getOwner(project) : '']"
            :active="false"
        >
          <ToolTip class="text-success" :value="moment(project.start_date).format('Do MMM YYYY') + ' - ' + moment(project.end_date).format('Do MMM YYYY')">
            {{ duration(project.start_date, project.end_date) }}
          </ToolTip>
        </Card>
      </div>
      <div v-else>
        <CardList v-for="project in completed" :key="project.id"
                  :label="project.title + [!isOwner(project) ? ' by ' + getOwner(project) : '']"
                  :date="moment(project.end_date).format('Do MMMM YYYY')"
                  :active="false"
        >
          <ToolTip class="text-success" :value="moment(project.start_date).format('Do MMM YYYY') + ' - ' + moment(project.end_date).format('Do MMM YYYY')">
            {{ duration(project.start_date, project.end_date) }}
          </ToolTip>
        </CardList>
      </div>
    </Accordion>

  </Authenticated>

  <Project
      v-else
      :projectKey="activeProject.key"
      @changeVue="selectedVue = 'list-projects'"
  />

  <NewProjectModal v-if="newProject" :friends="friends" @closeModal="projectAdded" />
</template>

<style scoped>

</style>
