<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Sidebar, Input, Textarea, Button, Panel, Select, Modal } from '@/components/index'
import NoteServices from '@/utils/services/noteServices'
import Editor from '@tinymce/tinymce-vue'
import { store } from '@/store'
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const props = defineProps(['noteKey'])
defineEmits(['changeVue', 'deleteNote'])
const service = new NoteServices()
const deleteNote = ref(false)
const user = store.user
const note = ref([])
const colour = ref(null)
const title = ref('')
const description = ref('')
const content = ref('')
const value = ref('')
const friends = ref(null)
const edit = ref(false)
const titleError = ref(false)
const descriptionError = ref(false)
const friendsAdded = ref([])
const access = ref('read')
const editNote = ref(false)
const accessOptions = ref([
  { value: 'read', label: 'Read' },
  { value: 'write', label: 'Write' }
])

const addToList = (id) => {
  if (friendsAdded.value.includes(id)) {
    const index = friendsAdded.value.indexOf(id)
    if (index > -1) {
      friendsAdded.value.splice(index, 1)
    }
  } else {
    friendsAdded.value.push(id)
  }
}

const canEdit = (users) => {
  let result = false
  users.forEach(x => {
    if (x.id === user.id) {
      if (x.pivot.access === 'write') {
        result = true
      }
    }
  })

  return result
}

const checkInvite = (friend) => {
  let check = false
  note.value.users.forEach(element => {
    if (element.id === friend) {
      check = true
    }
  })
  return check
}

const updateNote = () => {
  service.updateNote(props.noteKey, {
    content: content.value
  }).then((response) => {
    value.value = response.data.content
    // eslint-disable-next-line no-undef
    UIkit.notification({
      message: response.data.message,
      status: 'success',
      pos: 'bottom-right',
      timeout: 5000
    })
  }).catch(() => {
  })
}

const updateNoteDetails = () => {
  if (title.value === '') {
    titleError.value = true
    return
  }
  titleError.value = false

  if (description.value) {
    if (description.value.length > 144) {
      descriptionError.value = true
      return
    }
    descriptionError.value = false
  }

  service.updateNoteDetails(props.noteKey, {
    title: title.value,
    description: description.value,
    colour: colour.value,
    access: access.value,
    friends: friendsAdded.value
  }).then((response) => {
    title.value = response.data.title
    description.value = response.data.description
    // eslint-disable-next-line no-undef
    UIkit.notification({
      message: response.data.message,
      status: 'success',
      pos: 'bottom-right',
      timeout: 5000
    })
    editNote.value = false
  }).catch(() => {

  })
}

onMounted(() => {
  service.getNote(props.noteKey, user.id)
    .then((response) => {
      colour.value = response.data.note.colour
      note.value = response.data.note
      content.value = response.data.note.content
      value.value = response.data.note.content
      title.value = note.value.title
      description.value = note.value.description
      friends.value = response.data.friends
    })
    .catch(() => {

    })
})
</script>

<template>
  <Authenticated>
    <template #header>
      <Button action="info" @click="$emit('changeVue')">
        <i class="fa-solid fa-arrow-left"></i> Back
      </Button>
      <span>{{ title }}</span>
      <div>
        <Button v-if="note.user_id === user.id" action="danger" @click="deleteNote = true">
          <i class="fa-solid fa-trash"></i> Delete
        </Button>
      </div>
    </template>

    <Panel :style="'border-top: 2px solid ' + (colour === null ? 'var(--primaryColour)' : colour) ">
      <div class="note-controls">
        <Button v-if="note.users && canEdit(note.users)" @click="edit = !edit" action="dark">
          {{ edit ? 'Preview' : 'Edit' }}
        </Button>
        <div>{{ description }}</div>
        <a v-if="note.user_id === user.id" @click="editNote = true">
          <i class="fa-solid fa-cog"></i>
        </a>
      </div>
      <hr />
      <div v-if="edit">
        <div class="element-section">
          <editor
              api-key="h8gmy3cnobj2akxw0sts8xfmpka97an30s7zs5mcbh8jbh4t"
              v-model="content"
              :init="{
                height: 500,
                menubar: false,
                branding: false,
                plugins: [
                  'advlist','autolink',
                  'lists','link','charmap','searchreplace','visualblocks',
                  'fullscreen','insertdatetime','media','table'
                ],
                toolbar:
                  'undo redo | casechange blocks | bold italic underline link backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | table'
              }"
          />
        </div>
        <div>
          <Button @click="updateNote" action="success">
            Save
          </Button>
        </div>
      </div>
      <div v-else>
        <div class="preview" v-html="value"></div>
      </div>
    </Panel>

    <Sidebar
        v-if="editNote"
        title="Edit Note"
        @closeModal="editNote = false"
    >
      <div class="element-section">
        Note title
        <Input v-model="title" />
      </div>

      <div class="element-section">
        Note description
        <Textarea v-model="description" placeholder="Description of event" />
      </div>

      <div class="element-section">
        Colour
        <Input v-model="colour" type="color" />
      </div>

      <div class="element-section">
        Access
        <Select
            v-model="access"
            :options="accessOptions"
        />
      </div>

      <div class="element-section">
        Share to Friends
        <div v-for="friend in friends" :key="friend.id">
            <span>
              <input type="checkbox" name="checkbox" class="uk-checkbox uk-margin-small-right"
                     @click="addToList(friend.id)"
                     :checked="checkInvite(friend.id)">
              <label>{{ friend.first_name }} {{ friend.last_name }}</label>
            </span>
        </div>
      </div>

      <Button action="success" class="full-width" @click="updateNoteDetails">Save Changes</Button>
    </Sidebar>

  </Authenticated>

  <Modal
      v-if="deleteNote"
      :confirm="true"
      title="Are you sure you want to delete this note?"
      @closeModal="deleteNote = false"
      @confirm="$emit('deleteNote', note.key)"
  >

  </Modal>
</template>

<style scoped>
.note-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.note-controls > a {
  transition: .2s;
  color: var(--primaryColour);
}
.note-controls > a:hover {
  color: var(--tertiaryColour);
}

.preview {
  overflow-x: scroll;
  padding-bottom: 10px;
}
</style>
