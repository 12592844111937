<script setup>
import { Checkbox, Modal, Input, ToolTip, Toggle, Button, Textarea } from '../../../components'
import ProjectServices from '@/utils/services/projectServices'
import Datepicker from '@vuepic/vue-datepicker'
import { store } from '@/store'
import { defineProps, onMounted, defineEmits, ref } from 'vue'

const props = defineProps(['friends'])
const emit = defineEmits(['closeModal'])

const title = ref('')
const resource = ref(false)
const description = ref('')
const date = ref(null)
const fixedFriends = ref([])
const errors = ref([])
const service = new ProjectServices()

const submit = () => {
  errors.value = []
  if (title.value === '') {
    errors.value.title = 'A title is required'
  }
  if (!date.value) {
    errors.value.date = 'Dates are required'
  }
  if (description.value > 144) {
    errors.value.description = 'Description cannot be longer than 144 charcters'
  }

  if (errors.value.length === 0) {
    const data = {
      title: title.value,
      date: date.value,
      resource: resource.value,
      description: description.value,
      friends: fixedFriends.value
    }
    service.createProject(store.user.id, data)
      .then((response) => {
        emit('closeModal', response.data)
      })
  }
}

onMounted(() => {
  props.friends.forEach(x => {
    fixedFriends.value.push(
      {
        id: x.id,
        name: x.first_name + ' ' + x.last_name,
        selected: false
      }
    )
  })
})
</script>

<template>
  <Modal
      title="Create New Project"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div class="element-section">
        Project title
        <Input v-model="title" placeholder="Project title" />
      </div>

      <div class="element-section">
        Select project start and end date
        <Datepicker
            v-model="date"
            format="dd/MM/yyyy"
            range
            multiCalendars
            :enableTimePicker="false"
            placeholder="Start/End Date"
            showNowButton
            :min-date="new Date()"
        />
      </div>

      <div class="element-section">
        <div class="helper">
          Manage a list of resources?
          <ToolTip value="Keep track of your resources and materials.">
            <i class="fa-solid fa-circle-question btn-icon-info"></i>
          </ToolTip>
        </div>
        <Toggle @click="resource = !resource" v-model="resource"/>
      </div>

      <div class="element-section">
        Description of project
        <Textarea v-model="description" :length="144" />
      </div>

      <div>
        Invite friends
        <div class="grid">
          <div v-for="(friend, index) in fixedFriends" :key="friend.id">
            <Checkbox :fieldId="friend.name" v-model:checked="fixedFriends[index]['selected']" :label="friend.name" />
          </div>
        </div>
      </div>
      <hr/>
      <Button action="success" @click="submit">
        Create
      </Button>
    </template>
  </Modal>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  max-height: 160px;
  overflow-y: scroll;
}
</style>
