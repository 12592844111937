<script setup>
import { Modal, Input, ToolTip, Alert } from '@/components/index.js'
import WishlistServices from '../../../utils/services/wishlistServices'
import { store } from '../../../store'
import { defineEmits, ref, onMounted } from 'vue'

defineEmits(['closeModal'])

const templateItems = ref()
const name = ref('')
const link = ref('')
const price = ref('0.00')
const editRow = ref({ id: 0 })
const saveRow = ref(false)
const user = store.user
const service = new WishlistServices()

const reset = () => {
  name.value = ''
  link.value = ''
  price.value = '0.00'
}

const addItem = () => {
  service.addTemplateItem(user.id, {
    name: name.value,
    link: link.value,
    price: price.value
  }).then((response) => {
    templateItems.value.push(response.data)
    reset()
  })
}

const editItem = (id, update) => {
  if (!update) {
    if (editRow.value.id === 0) {
      templateItems.value.forEach(element => {
        if (element.id === id) {
          Object.assign(editRow.value, element)
        }
      })
    } else {
      editRow.value = { id: 0 }
      saveRow.value = false
    }
  } else {
    service.updateTemplateItem(editRow.value.id, user.id, {
      name: editRow.value.name,
      link: editRow.value.link,
      price: editRow.value.price
    }).then((response) => {
      templateItems.value.forEach((x, index) => {
        if (x.id === response.data.id) {
          templateItems.value[index] = response.data
        }
      })
      editRow.value = { id: 0 }
    })
  }
}

const deleteItem = (id) => {
  service.deleteTemplateItem(id, user.id)
    .then(() => {
      const items = []
      templateItems.value.forEach(x => {
        if (x.id !== id) {
          items.push(x)
        }
      })
      templateItems.value = items
    })
}

const truncate = (value) => {
  if (value !== null) {
    if (value.length > 30) {
      return value.substring(0, 30) + '...'
    }
  }

  return value
}

onMounted(() => {
  service.getTemplateItems(user.id)
    .then((response) => {
      templateItems.value = response.data
    })
})

</script>

<template>
  <Modal
    title="Template Items"
    @closeModal="$emit('closeModal')"
    big
  >
    <template #content>
      <Alert
          text="Create template items to quickly add to your lists."
          type="info"
      />
      <Table class="items-table">
        <tr class="items-header">
          <th>Name</th>
          <th>Link</th>
          <th class="column-medium">Price</th>
          <th class="column-small"></th>
        </tr>
        <tbody>
        <tr class="item" v-for="item in templateItems" :key="item.id">
          <td>
            <div v-if="editRow.id === item.id">
              <Input v-model="editRow.name" small @input="saveRow = true" />
            </div>
            <div v-else>
                <span v-if="item.name.length > 30">
                  <ToolTip :value="item.name">
                    {{ truncate(item.name) }}
                  </ToolTip>
                </span>
              <span v-else>
                  {{ item.name }}
                </span>
            </div>
          </td>
          <td>
            <div v-if="editRow.id === item.id">
              <Input v-model="editRow.link" small placeholder="(optional)" @input="saveRow = true" />
            </div>
            <div v-else>
              <a :href="item.link" target="_blank">{{ truncate(item.link) }}</a>
            </div>
          </td>
          <td class="price-column">
            <div v-if="editRow.id === item.id">
              <Input
                  v-model="editRow.price"
                  type="number"
                  icon small
                  :step="0.01"
                  @input="saveRow = true"
              >£</Input>
              <div class="form-info">
                <span class="form-description">(Price of one)</span>
              </div>
            </div>
            <div v-else class="price-static">
              {{ new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(item.price) }}
            </div>
          </td>
          <td class="item-action">
            <ToolTip value="Edit">
              <a v-if="editRow.id === 0" class="btn btn-icon-info" @click="editItem(item.id, false)">
                <i class="fa-regular fa-pen-to-square"></i>
              </a>
            </ToolTip>

            <ToolTip value="Cancel">
              <a v-if="editRow.id === item.id && saveRow === false" class="btn btn-icon-danger" @click="editItem(item.id, false)">
                <i class="fa-solid fa-xmark"></i>
              </a>
            </ToolTip>

            <ToolTip value="Save">
              <a v-if="editRow.id === item.id && saveRow === true"
                 @click="editItem(item.id, true)"
                 class="btn btn-icon-success">
                <i class="fa-solid fa-check"></i>
              </a>
            </ToolTip>

            <ToolTip value="Remove">
              <a v-if="editRow.id === 0" @click="deleteItem(item.id)"
                  class="btn btn-icon-danger">
                <i class="fa-solid fa-trash"></i>
              </a>
            </ToolTip>
          </td>
        </tr>
        <tr class="item">
          <td><Input v-model="name" small /></td>
          <td><Input v-model="link" small placeholder="(optional)" /></td>
          <td>
            <Input
                v-model="price"
                type="number"
                icon small
                :step="0.01"
                id="price"
            >£</Input>
            <div class="form-info">
              <span class="form-description">(Price of one)</span>
            </div>
          </td>
          <td class="item-action">
            <ToolTip value="Add">
              <a class="btn btn-icon-success" @click="addItem()"><i class="fas fa-plus"></i></a>
            </ToolTip>
          </td>
        </tr>
        </tbody>
      </Table>
    </template>
  </Modal>
</template>

<style scoped>
.items-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.items-header > th {
  height: 50px;
  font-weight: normal !important;
}
.item {
  border-top: 1px solid #CCCCCC !important;
  height: 65px;
}
.column-small {
  width: 10%;
}
.column-medium {
  width: 20%;
  min-width: 100px;
}
td {
  padding: 0 5px;
}

.form-info {
  position: relative;
}
.form-description {
  position: absolute;
  bottom: -16px;
  left: 2px;
  font-size: 13px;
}

.item-action {
  display: flex;
  width: 100px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
