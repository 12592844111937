<script setup>
import { Button } from '@/components/index'
import { defineProps } from 'vue'
import { store } from '@/store'
import moment from 'moment/moment'
import router from '@/router'

defineProps(['currentEvent'])
const user = store.user

const dayDifference = (day1, day2) => {
  const start = moment(day1)
  const end = day2.startOf('day')
  const difference = start.diff(end, 'days')
  let amount = ''
  let text = ''

  if (difference === 0) {
    amount = 'Today'
    text = ''
  } else if (difference > 0) {
    amount = difference
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Left'
  } else {
    amount = Math.abs(difference)
    text = amount > 1 ? 'Days' : 'Day'
    text = text + ' Ago'
  }

  return {
    amount,
    text
  }
}
</script>

<template>
  <div>
<!--    {{ currentEvent }}-->
    <div v-if="currentEvent.description" class="current-event-info">
      {{ currentEvent.description }}
    </div>
    Members
    <div class="current-event-info">
      <ul class="members">
        <li v-for="member in currentEvent.users" :key="member.id" class="member">
          <span>
            {{ member.id === user.id ? 'Me' : member.first_name + ' ' + member.last_name }}
          </span>
        </li>
      </ul>
    </div>
    <div class="current-event-info">
      <p class="days-count">
        {{ dayDifference(currentEvent.start_date, moment()).amount }}
      </p>
      <p class="days-text">
        {{ dayDifference(currentEvent.start_date, moment()).text }} <br v-if="dayDifference(currentEvent.start_date, moment()).text" />
        {{ moment(currentEvent.start_date).format('Do MMMM YYYY') }}
      </p>
    </div>
    <div v-if="currentEvent.tasks.length > 0" class="current-event-info">
      <ul class="members">
        <li v-for="task in currentEvent.tasks" :key="task.id" class="member">
          <span>
            {{ task.name }}
          </span>
        </li>
      </ul>
    </div>
    <div v-if="currentEvent.resources.length > 0" class="current-event-info">
      <ul class="members">
        <li v-for="resource in currentEvent.resources" :key="resource.id" class="member">
          <span>
            {{ resource.name }}
          </span>
        </li>
      </ul>
    </div>
    <div>
      <Button action="info" class="full-width" @click="router.push({ name: 'Projects' })">
        View
      </Button>
    </div>
  </div>
</template>

<style scoped>
.members {
  list-style: none;
  padding: 0;
  margin: 0;
}
.member {
  display: flex;
  justify-content: space-between;
}
.days-count {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.days-text {
  text-align: center;
  margin: 0;
}
</style>
