<template>
  <button
      :type="type"
      class="sol-button"
      :class="[
          action ? 'btn-' + action : '',
          disabled ? 'disabled' : '',
      ]"
      :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'SolButton',
  props: {
    type: {
      type: String,
      default: 'submit'
    },
    dark: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.sol-button {
  font-family: 'Kanit', sans-serif !important;
  padding: 0 20px;
  border-radius: 10px;
  text-transform: capitalize;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: .875rem;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  transition: .2s;
  border: 3px solid;
  cursor: pointer;
  color: white;
}

.btn-dark {
  background-color: var(--secondaryColour);
  border: 3px solid var(--secondaryColour) !important;
}
.btn-dark:hover {
  background-color: rgba(43, 122, 121, .7);
}

.btn-success {
  border-color: var(--successColour)!important;
  background-color: var(--successColour);
}
.btn-success:hover {
  background-color: rgba(92, 184, 92, .7);
}

.btn-info {
  border-color: var(--infoColour)!important;
  background-color: var(--infoColour);
}
.btn-info:hover {
  background-color: rgba(30, 135, 240, .7);
}

.btn-warning {
  border-color: var(--warningColour)!important;
}
.btn-warning:hover {
  background-color: rgba(250, 160, 90, .7);
}

.btn-danger {
  border-color: var(--dangerColour)!important;
  background-color: var(--dangerColour);
}
.btn-danger:hover {
  background-color: rgba(220, 53, 69, .7);
}

.disabled {
  border-color: #CCCCCC !important;
  color: black !important;
  background-color: #CCCCCC !important;
  cursor: not-allowed !important;
}
</style>
