import axios from 'axios'

class ProjectServices {
  async getProjects (userId) {
    return await axios.get('/get-projects/' + userId)
  }

  async createProject (userId, data) {
    return await axios.post('/create-project/' + userId, data)
  }

  async getProject (projectId) {
    return await axios.get('/get-project/' + projectId)
  }

  async task (parameters, data) {
    return await axios.post('/tasks' + parameters, data)
  }

  async resource (parameters, data) {
    return await axios.post('/resources' + parameters, data)
  }

  async updateMember (projectId, userId, data) {
    return await axios.patch('/update-member/' + projectId + '/' + userId, data)
  }

  async projectCompleted (projectKey) {
    return await axios.post('/submit/' + projectKey)
  }
}

export default ProjectServices
