<template>
  <Modal modal_id="new-password" title="New Password" :sidebar="false">
    <table class="uk-table uk-table-divider">
      <thead>
      <tr>
        <th class="uk-table-expand">Service Name</th>
        <th class="uk-table-expand">Password</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><input type="text" class="uk-input uk-form-small"></td>
        <td>
          <div class="uk-margin">
            <div class="uk-inline">
              <a class="uk-form-icon uk-form-icon-flip uk-text-decoration-none" @click="showPassword">
                <i class="fas fa-eye"></i>
              </a>
              <input :type="this.show ? 'text' : 'password'" class="uk-input uk-form-small">
            </div>
          </div>
        </td>
        <td id="add-button"><a href="" class="btn btn-icon-add"><i class="fas fa-plus"></i></a></td>
      </tr>
      </tbody>
    </table>
  </Modal>
</template>

<script>
import Modal from '@/components/section/system/SystemModal.vue'

export default {
  name: 'NewPasswordModal',
  components: {
    Modal
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    showPassword () {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>

</style>
