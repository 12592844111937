<template>
  <Authenticated>
    <template #header>
      Dashboard
      <div class="friends-icon" @click="friendSidebar = true">
        <i class="fa-solid fa-users"></i>
      </div>
    </template>

    <div class="grid-2">
      <WeatherWidget />
      <SportWidget />
    </div>

    <div class="grid-2">
      <CalendarWidget v-if="events" :upcomingEvents="events" />
      <WishlistWidget v-if="wishlists" :wishlists="wishlists" />
    </div>

  </Authenticated>

  <QuickProfile
      v-if="activeUser"
      :user="activeUser"
      @closeActiveUser="closeActiveUser"
  />

  <FriendSidebar
      v-if="friendSidebar"
      :friends="friends"
      :potentialFriends="potentialFriends"
      :incoming="incoming"
      :outgoing="outgoing"
      @setActiveUser="setActiveUser"
      @addFriend="addFriend"
      @acceptRequest="acceptRequest"
      @declineRequest="declineRequest"
      @cancelRequest="cancelRequest"
      @removeFriend="removeFriend"
      @closeModal="friendSidebar = false"
  />

</template>

<script>
import DashboardServices from '../utils/services/dashboardServices'
import Authenticated from './layouts/AuthenticatedLayout.vue'
import { QuickProfile } from '../components/section/system/index'
import { WeatherWidget, CalendarWidget, WishlistWidget, SportWidget } from '../components/section/widgets/index'
import { store } from '../store'
import { onMounted, ref } from 'vue'
import FriendSidebar from '../components/section/system/SystemFriendSidebar.vue'

export default {
  name: 'DashboardPage',
  components: {
    FriendSidebar,
    SportWidget,
    WishlistWidget,
    QuickProfile,
    CalendarWidget,
    WeatherWidget,
    Authenticated
  },
  setup () {
    const potentialFriends = ref(null)
    const friends = ref(null)
    const incoming = ref(null)
    const outgoing = ref(null)
    const events = ref(null)
    const activeUser = ref(null)
    const wishlists = ref(null)
    const service = new DashboardServices()
    const friendSidebar = ref(false)

    const addFriend = (id) => {
      service.addFriend(store.user.id, id)
        .then((response) => {
          getDashboardData()
          // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'success',
            pos: 'bottom-right',
            timeout: 5000
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const acceptRequest = (id) => {
      service.acceptRequest(store.user.id, id)
        .then((response) => {
          getDashboardData()
          // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'success',
            pos: 'bottom-right',
            timeout: 5000
          })
        })
    }

    const declineRequest = (id) => {
      service.declineRequest(store.user.id, id)
        .then((response) => {
          getDashboardData()
          // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'danger',
            pos: 'bottom-right',
            timeout: 5000
          })
        })
    }

    const cancelRequest = (id) => {
      service.cancelRequest(store.user.id, id)
        .then((response) => {
          getDashboardData()
          // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'danger',
            pos: 'bottom-right',
            timeout: 5000
          })
        })
    }

    const removeFriend = (id) => {
      service.removeFriend(store.user.id, id)
        .then((response) => {
          getDashboardData()
          // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'danger',
            pos: 'bottom-right',
            timeout: 5000
          })
        })
    }

    const getDashboardData = () => {
      service.getData(store.user.id)
        .then((response) => {
          potentialFriends.value = response.data.potentialFriends
          friends.value = response.data.friends
          incoming.value = response.data.incomingFriends
          outgoing.value = response.data.outgoingFriends
          events.value = response.data.events
          wishlists.value = response.data.wishlists
        })
    }

    const setActiveUser = (user) => {
      activeUser.value = user
    }

    const closeActiveUser = () => {
      activeUser.value = null
    }

    onMounted(() => {
      getDashboardData();
    })

    return {
      friendSidebar,
      potentialFriends,
      friends,
      incoming,
      outgoing,
      events,
      activeUser,
      wishlists,
      addFriend,
      declineRequest,
      cancelRequest,
      removeFriend,
      acceptRequest,
      setActiveUser,
      closeActiveUser
    }
  }
}
</script>

<style scoped>
.icon {
  cursor: pointer;
}
.user-name {
  vertical-align: middle !important;
  margin-left: 10px !important;
  cursor: pointer;
}
.friends-icon {
  color: var(--primaryColour);
  cursor: pointer;
  transition: .2s;
}
.friends-icon:hover {
  color: var(--tertiaryColour);
  transition: .2s;
}
.grid-2 {
  margin-bottom: 10px;
}
</style>
