import axios from 'axios'

class CalendarServices {
  async getCalendar (userId, month) {
    return await axios.get('/get-calendar/' + userId + '/' + month.format('YYYY-MM-DD'))
  }

  async changeDecision (event, userId, decision) {
    return await axios.patch('/update-decision/' + event + '/' + userId, {
      decision
    })
  }

  async deleteEvent (event, userId) {
    return await axios.delete('/delete-event/' + event + '/' + userId)
  }

  async editEvent (event, userId, data) {
    return await axios.patch('/edit-event/' + event + '/' + userId, data)
  }

  async createEvent (userId, data) {
    return await axios.post('/create-event/' + userId, data)
  }
}

export default CalendarServices
