<script setup>
import { Modal, Input, Checkbox, Button, Textarea } from '@/components/index.js'
import Datepicker from '@vuepic/vue-datepicker'
import CalendarServices from '@/utils/services/calendarServices'
import { store } from '@/store'
import router from '@/router'
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const props = defineProps(['friends'])
defineEmits(['closeModal'])
const service = new CalendarServices()
const errors = ref([])
const fixedFriends = ref([])
const title = ref('')
const description = ref('')
const startDate = ref(null)
const endDate = ref(null)

const submit = () => {
  errors.value = []
  if (title.value === '') {
    errors.value.title = 'A title is required'
  }
  if (description.value > 144) {
    errors.value.description = 'Too many characters (max: 144)'
  }
  if (startDate.value === null) {
    errors.value.startDate = 'A start date is required'
  }

  if (errors.value.length === 0) {
    const data = {
      title: title.value,
      description: description.value,
      date: endDate.value !== null ? [startDate.value, endDate.value] : [startDate.value, startDate.value],
      friends: fixedFriends.value
    }

    service.createEvent(store.user.id, data)
      .then(() => {
        router.go()
      })
  }
}

onMounted(() => {
  props.friends.forEach(x => {
    fixedFriends.value.push(
      {
        id: x.id,
        name: x.first_name + ' ' + x.last_name,
        selected: false
      }
    )
  })
})
</script>

<template>
  <Modal
      title="Create New Event"
      @closeModal="$emit('closeModal')"
  >
    <template #content>
      <div class="element-section">
        Title of event <span class="text-danger">{{ errors['title'] }}</span>
        <Input v-model="title" placeholder="Title of event" />
      </div>

      <div class="element-section">
        Description <span class="text-danger">{{ errors['description'] }}</span>
        <Textarea v-model="description" :length="144" placeholder="Description of event" />
      </div>

      <div class="element-section">
        Select start date <span class="text-danger">{{ errors['startDate'] }}</span>
        <Datepicker
            v-model="startDate"
            format="dd/MM/yyyy"
            multiCalendars
            :enableTimePicker="false"
            placeholder="Start date"
            showNowButton
        />
      </div>
      <div class="element-section" v-if="startDate">
        Select end date (optional)
        <Datepicker
            v-model="endDate"
            format="dd/MM/yyyy"
            multiCalendars
            :enableTimePicker="false"
            placeholder="End date"
            :minDate="new Date(startDate)"
            showNowButton
        />
      </div>

      <div class="element-section">
        Invite friends
        <div class="grid">
          <div v-for="(friend, index) in fixedFriends" :key="friend.id">
            <Checkbox :fieldId="friend.name" v-model:checked="fixedFriends[index]['selected']" :label="friend.name" />
          </div>
        </div>
      </div>
      <hr />
      <Button action="success" @click="submit">
        Create
      </Button>
    </template>
  </Modal>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  max-height: 160px;
  overflow-y: scroll;
}
</style>
