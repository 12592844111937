<script setup>
import { Panel, ToolTip, Input, Textarea, Select } from '@/components/index'
import SettingServices from '@/utils/services/settingServices'
import { store } from '@/store'
import moment from 'moment'
import { onMounted, ref } from 'vue'

const user = store.user
const service = new SettingServices()
const socialEdit = ref(false)
const aboutEdit = ref(false)
const profile = ref()
const friends = ref()
const socials = ref()
const platform = ref()
const link = ref('')
const tempAbout = ref({
  about: '',
  job_title: ''
})

const genderOptions = ref([
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
])

const platformOptions = ref([
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'linkedIn', label: 'LinkedIn' },
  { value: 'twitter/x', label: 'Twitter/X' },
  { value: 'tiktok', label: 'Tiktok' },
  { value: 'youtube', label: 'Youtube' },
  { value: 'discord', label: 'Discord' },
  { value: 'website', label: 'Website' },
  { value: 'connect', label: 'Connect' }
])

const updateSocial = () => {
  const data = {
    platform: platform.value,
    link: link.value
  }

  service.updateSocials(user.id, data)
    .then((response) => {
      socials.value = response.data
      link.value = null
      platform.value = null
    })
}

const updateAbout = () => {
  const data = {
    about: tempAbout.value.about,
    job_title: tempAbout.value.job_title,
    gender: tempAbout.value.gender
  }

  service.updateAbout(user.id, data)
    .then((response) => {
      profile.value = response.data
      tempAbout.value = {
        about: profile.value.about ?? '',
        job_title: profile.value.job_title ?? '',
        gender: profile.value.gender ?? null
      }
      aboutEdit.value = false
    })
}

onMounted(() => {
  service.getProfile(user.id)
    .then((response) => {
      profile.value = response.data.profile
      friends.value = response.data.friends
      socials.value = response.data.profile?.socials
      tempAbout.value = {
        about: profile.value?.about ?? '',
        job_title: profile.value?.job_title ?? '',
        gender: profile.value?.gender ?? null
      }
    })
})
</script>

<template>
  <div class="profile-banner">
    <div class="profile-picture">
      <img src="../../../assets/default-avatar.png" height="100" width="100" />
    </div>
  </div>
  <div class="grid-3">
    <Panel>
      <div class="section-title">
        Socials
        <ToolTip v-if="!socialEdit" value="Edit">
          <a class="btn btn-icon-info" @click="socialEdit = true"><i class="fas fa-pen-to-square"></i></a>
        </ToolTip>
        <ToolTip v-else value="Cancel">
          <a class="btn btn-icon-danger" @click="socialEdit = false"><i class="fas fa-times"></i></a>
        </ToolTip>
      </div>
      <div v-if="socialEdit" class="social-form">
        <Select
            v-model="platform"
            :options="platformOptions"
        />
        <Input v-model="link" placeholder="Link" />
        <ToolTip value="Add">
          <a class="btn btn-icon-success" @click="updateSocial"><i class="fas fa-plus"></i></a>
        </ToolTip>
      </div>
      <ul class="socials">
        <li v-for="social in socials" :key="social.name">
          <span class="social-icon" v-html="social.icon"></span>
          <span class="social-text">
            <a :href="social.link" target="_blank">
              {{ social.name }}
            </a>
          </span>
        </li>
      </ul>
    </Panel>

    <Panel>
      <div class="section-title">
        About
        <ToolTip v-if="!aboutEdit" value="Edit">
          <a  class="btn btn-icon-info" @click="aboutEdit = true"><i class="fas fa-pen-to-square"></i></a>
        </ToolTip>
        <span class="about-controls" v-else>
          <ToolTip value="Save">
            <a class="btn btn-icon-success" @click="updateAbout"><i class="fas fa-check"></i></a>
          </ToolTip>
          <ToolTip value="Cancel">
            <a class="btn btn-icon-danger" @click="aboutEdit = false"><i class="fas fa-times"></i></a>
          </ToolTip>
        </span>
      </div>
      <p v-if="!aboutEdit">{{ profile && profile.about ? profile.about : '' }}</p>
      <Textarea v-else class="element-section" v-model="tempAbout.about" :length="144"></Textarea>
      <ul class="about">
        <li>
          <span class="about-icon" v-if="(profile && profile.job_title) || aboutEdit">
            <ToolTip value="Job Title">
              <i class="fa-solid fa-building"></i>
            </ToolTip>
          </span>
          <span class="about-text">
            <span v-if="!aboutEdit">
              {{ profile && profile.job_title ? profile.job_title : '' }}
            </span>
            <Input v-else v-model="tempAbout.job_title" placeholder="Job title" />
          </span>
        </li>
        <li>
          <span class="about-icon">
            <ToolTip value="Birthday">
              <i class="fa-solid fa-cake-candles"></i>
            </ToolTip>
          </span>
          <span class="about-text">
            {{ moment(user.date_of_birth).format('Do MMMM YYYY') }}
          </span>
        </li>
        <li>
          <span class="about-icon" v-if="(profile && profile.gender) || aboutEdit">
            <ToolTip value="Gender">
              <i class="fa-solid fa-person-half-dress"></i>
            </ToolTip>
          </span>
          <span class="about-text">
            <span v-if="!aboutEdit">
              {{ profile && profile.gender ? profile.gender : '' }}
            </span>
            <Select
                v-else
                v-model="tempAbout.gender"
                :options="genderOptions"
            />
          </span>
        </li>
        <li>
          <span class="about-icon">
            <ToolTip value="Joined">
              <i class="fa-solid fa-star"></i>
            </ToolTip>
          </span>
          <span class="about-text">
            {{ moment(user.created_at).format('MMMM YYYY') }}
          </span>
        </li>
      </ul>
    </Panel>
    <Panel>
      <div class="section-title">
        Friends
      </div>
      <div v-for="friend in friends" :key="friend.id" class="friend-item">
        <img class="uk-border-circle outline" src="../../../assets/default-avatar.png" height="56" width="56"
             alt="Avatar"/>
        <span>{{ friend.first_name + ' ' + friend.last_name }}</span>
      </div>
    </Panel>
  </div>
</template>

<style scoped>
.profile-banner {
  position: relative;
  border-radius: 20px;
  height: 200px;
  border: 1px solid transparent;
  background-color: var(--secondaryColour);
  margin-bottom: 60px;
}
.profile-picture {
  position: absolute;
  border-radius: 100%;
  border: 5px solid var(--bodyColour);
  left: 50%;
  bottom: 0;
  overflow: hidden;
  transform: translate(-50%, 50%);
}
.section-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.social-form {
  display: flex;
  gap: 4px;
  justify-content: space-evenly;
  margin: auto 0;
}
.socials {
  list-style: none;
  margin: 0;
  padding: 0;
}
.socials > li {
  display: flex;
  margin-bottom: 5px;
}
.socials > li > .social-icon {
  display: inline-block;
  font-size: 25px;
  height: 32px;
  width: 32px;
  color: var(--primaryColour);
  text-align: center;
  margin-right: 10px;
}
.socials > li > .social-text {
  margin: auto 0;
}
.about {
  list-style: none;
  margin: 0;
  padding: 0;
}
.about-controls {
  display: flex;
  gap: 5px;
}
.about > li {
  display: flex;
  margin-bottom: 5px;
}
.about > li > .about-icon {
  display: inline-block;
  font-size: 25px;
  height: 32px;
  width: 32px;
  color: var(--primaryColour);
  text-align: center;
  margin-right: 10px;
}
.about > li > .about-text {
  margin: auto 0;
  flex: 1;
  text-transform: capitalize;
}
.friend-item {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}
.friend-item > span {
  margin: auto 0;
}
</style>
