<script setup>
import { defineProps, defineEmits, ref } from 'vue'

defineProps({
  titles: {
    type: Array,
    required: true
  }
})

defineEmits(['change'])

const currentTab = ref(1)
</script>

<template>
  <ul class="switch">
    <li v-for="(title, index) in titles" :key="title"
        :class="currentTab === index+1 ? 'switch-active' : ''"
        @click="currentTab = index+1; $emit('change', index+1)"
    >
      {{ title }}
    </li>
  </ul>

  <div v-for="(title, index) in titles" :key="title">
    <slot v-if="currentTab === index+1" :name="'tab-' + (index+1)" />
  </div>
</template>

<style scoped>
.switch {
  display: flex;
  width: 100%;
  list-style: none;
  justify-content: space-around;
  padding: 0;
  border-bottom: 1px solid var(--primaryColour);
  gap: 15px;
  margin: 10px 0 20px 0;
}
.switch > li {
  padding: 10px 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.switch-active {
  position: relative;
  background-color: var(--primaryColour);
  color: white;
  border-radius: 10px 10px 0 0;
}
</style>
