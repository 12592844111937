import moment from 'moment'

const createSeries = (project) => {
  const userTasks = []
  project.users.forEach(x => {
    const tasks = []
    project.tasks.forEach(y => {
      if (y.assignee === x.pivot.user_id) {
        tasks.push({
          x: y.name,
          y: [
            new Date(moment(y.start_date).format('YYYY/MM/DD')).getTime(),
            new Date(moment(y.end_date).format('YYYY/MM/DD')).getTime()
          ],
          fillColor: x.pivot.colour
        })
      }
    })
    userTasks.push({
      name: x.first_name + ' ' + x.last_name,
      data: tasks
    })
  })

  return userTasks
}

export default createSeries
