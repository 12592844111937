<template>
   <div :id="modal_id" :class="format" uk-modal>
     <div class="uk-modal-dialog">
       <button class="uk-modal-close-default uk-border-circle uk-light" type="button" uk-close></button>
       <div class="uk-modal-header">
         <span v-if="modal_id === 'list' && sidebar" class="btn btn-info uk-margin-small-right" @click="toggleEditTitle"><i class="fa-regular fa-pen-to-square"></i></span>
         <span v-if="!editTitle">
           {{ title }}
         </span>
         <SolInput v-else id="title" type="text" class="uk-border-rounded uk-width-1-4 uk-form-small uk-margin-remove" v-model="listTitle" :placeholder="title" @keyup="wait" />
       </div>
       <div class="uk-modal-body uk-position-relative uk-flex">
         <div class="modal-content uk-flex-1">
           <slot></slot>
         </div>
         <div v-if="sidebar" class="modal-sidebar">
           <slot name="sidebar" />
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import { ref } from 'vue'
import SolInput from '../../SolInput.vue'
import axios from 'axios'
import router from '../../../router'

export default {
  name: 'SystemModal',
  components: {
    SolInput
  },
  props: {
    modal_id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    sidebar: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: ''
    },
    listId: {
      type: Number,
      required: false
    }
  },
  setup (props) {
    const listTitle = ref(props.title)
    const editTitle = ref(false)
    let typingTimer

    const toggleEditTitle = () => {
      editTitle.value = !editTitle.value
    }

    const wait = () => {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(submitTitle, 3000)
    }

    const submitTitle = () => {
      axios.patch('/update-name/' + props.listId, {
        title: listTitle.value
      })
        .then((response) => {
        // eslint-disable-next-line no-undef
          UIkit.notification({
            message: response.data,
            status: 'success',
            pos: 'bottom-right',
            timeout: 5000
          })
          setTimeout(reload, 1500)
        })
    }

    const reload = () => {
      router.go()
    }

    return {
      // eslint-disable-next-line vue/no-dupe-keys
      listTitle,
      editTitle,
      toggleEditTitle,
      wait
    }
  }
}
</script>

<style scoped>
.uk-modal-body {
  min-height: 275px;
  padding: 0;
}
.modal-sidebar {
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryColour);
  color: var(--bodyColour);
  padding: 5px 10px 12px 10px;
  min-width: 180px;
  overflow-x: scroll;
}

.modal-content {
  padding: 30px 30px 5px 30px;
  height: fit-content;
  overflow-x: scroll;
}

</style>
