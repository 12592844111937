<template>
  <div id="toggle" :class="modelValue ? 'active' : ''">
    <i class="indicator"></i>
  </div>
</template>

<script>
export default {
  name: 'toggleComp',
  props: {
    modelValue: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
#toggle {
  position: relative;
  display: block;
  width: 65px;
  height: 30px;
  border-radius: 160px;
  background-color: #CCCCCC;
  transition: .5s;
  cursor: pointer;
  box-shadow: inset 0 8px 60px rgba(0,0,0,0.1),
  inset 0 8px 8px rgba(0,0,0,0.1),
  inset 0 -4px 4px rgba(0,0,0,0.1);
}

#toggle .indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: var(--primaryColour);
  border-radius: 50%;
  transform: scale(0.9);
  transition: .2s;
  box-shadow: 0 8px 40px rgba(0,0,0,0.5),
  inset 0 4px 4px rgba(255,255,255,0.2),
  inset 0 -4px 4px rgba(255,255,255,0.2);
}
#toggle.active .indicator {
  transition: .2s;
  left: 35px;
}
#toggle.active {
  background-color: var(--secondaryColour);
}
</style>
