<template>
  <Authenticated>
    <div class="uk-position-center uk-text-center">
      <h1>404 - Page Not Found!</h1>
      <h3>Oops, I couldn't find the page you are looking for.</h3>
      <h3>Please use the navigation bar to the left to go somewhere familiar.</h3>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from './layouts/AuthenticatedLayout.vue'

export default {
  name: 'NotFound',
  components: {
    Authenticated
  }
}
</script>
