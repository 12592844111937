<script setup>
import { Input, Button } from '@/components/index'
import Guest from '../layouts/GuestLayout.vue'
import setAuthHeader from '../../utils/setAuthHeader'
import axios from 'axios'
import { store } from '@/store'
import router from '../../router'
import { onMounted, ref } from 'vue'

const email = ref(null)
const password = ref(null)
const loginError = ref(null)
const isLoading = ref(null)

const submit = () => {
  loginError.value = null
  isLoading.value = true
  axios.post('/login', {
    email: email.value,
    password: password.value
  })
    .then((response) => {
      isLoading.value = false
      handleStorage(response.data)
      setAuthHeader()
      store.isAuthenticated = localStorage.getItem('solToken')
      store.user = JSON.parse(localStorage.getItem('user'))
      router.push({ name: 'Dashboard' })
    }).catch((error) => {
      isLoading.value = false
      password.value = null
      if (error.code === 'ERR_NETWORK') {
        loginError.value = 'Connection Failed'
      } else {
        loginError.value = error.response.data.message
      }
    })
}

const handleStorage = (data) => {
  localStorage.setItem('solToken', data.token)
  localStorage.setItem('user', JSON.stringify(data.user))
}

onMounted(() => {
  const user = localStorage.getItem('solToken')
  if (user) {
    router.push({ name: 'Dashboard' })
  }
})

</script>

<template>
  <Guest>
    <div class="login-title">SOL</div>
    <div class="login-welcome">Welcome</div>
    <div class="login-error">{{ loginError }}</div>
    <form class="login-form" @submit.prevent="submit">
      <div>
        <Input id="email" type="email" placeholder="Email" v-model="email" required autofocus autocomplete="email" />
      </div>
      <div>
        <Input id="password" type="password" placeholder="Password" v-model="password" required autocomplete="current-password" />
        <a class="forgot-password">
          Forgot password?
        </a>
      </div>

      <div>
        <Button class="uk-width-expand" action="dark" :disabled="isLoading">
          Sign In
        </Button>
      </div>

      <div class="login-secondary-items">
        <span>New to Sol? <a href="http://cluster.local:8080/register">Create Account</a></span>
      </div>
    </form>
  </Guest>
</template>

<style scoped>
.login-title {
  color: var(--bodyColour);
  text-align: center;
  font-size: 30px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 5px;
}
.login-welcome {
  color: var(--bodyColour);
  letter-spacing: 5px;
}
.login-error {
  color: var(--dangerColour);
  text-align: center;
}
.login-form {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  color: var(--bodyColour);
}
.login-secondary-items {
  text-align: center;
}
.login-secondary-items > span > a {
  color: var(--primaryColour);
}
.forgot-password {
  float: right;
  color: var(--primaryColour);
}
</style>
