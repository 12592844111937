<script setup>
import Authenticated from '../layouts/AuthenticatedLayout.vue'
import { Accordion, Button, Card, Modal, CardList } from '@/components/index'
import ExpiredWishlistModal from './modals/ExpiredWishlistModal.vue'
import WishlistItemsModal from './modals/WishlistItemsModal.vue'
import NewWishlistModal from './modals/NewWishlistModal.vue'
import WishlistServices from '@/utils/services/wishlistServices'
import WishlistItemsPage from "./WishlistItemsPage.vue"
import TemplateItemsModal from './modals/TemplateItemsModal.vue'
import moment from 'moment'
import { store } from '@/store'
import {onBeforeMount, onMounted, ref} from 'vue'

const newWishlist = ref(false)
const activeList = ref({
  id: null,
  user: null,
  title: null,
  items: null
})
const myLists = ref(null)
const otherLists = ref(null)
const friends = ref(null)
const templateItems = ref(null)
const confirmationMessage = ref('')
const isLoading = ref(true)
const expiredList = ref(false)
const wishlistItems = ref(false)
const wishlistItemsPage = ref(false)
const templateModal = ref(false)
const confirmModal = ref(false)
const service = new WishlistServices()

const replaceList = (listId) => {
  myLists.value.forEach(element => {
    if (element.id === listId) {
      activeList.value = element
    }
  })

  otherLists.value.forEach(element => {
    if (element.id === listId) {
      activeList.value = element
    }
  })
}

const checkActivity = (list) => {
  replaceList(list.id)
  if (list.end_date > moment().format('YYYY-MM-DD')) {
    if (!store.user.preference || store.user.preference.wishlist === null || store.user.preference.wishlist.singleView === false) {
      wishlistItems.value = true
    } else {
      wishlistItemsPage.value = true
    }
  } else {
    expiredList.value = true
  }
}

const createdList = (list) => {
  myLists.value.push(list)
}

const modalConfirmation = (toDelete) => {
  if (toDelete) {
    wishlistItems.value = false
    confirmationMessage.value = 'Would you like to delete ' + activeList.value.title + '?'
    confirmModal.value = true
  } else {
    wishlistItems.value = false
    confirmationMessage.value = 'Would you like to activate ' + activeList.value.title + "? \n You won't be able to edit or delete items."
    confirmModal.value = true
  }
}

const deleteList = (id) => {
  service.deleteList(id)
    .then(() => {
      wishlistItems.value = false
      wishlistItemsPage.value = false
      myLists.value.forEach(element => {
        if (element.id === id) {
          myLists.value.pop(element)
        }
      })
    })
  confirmModal.value = false
}

const activateList = (id) => {
  service.activateList(id)
    .then((response) => {
      wishlistItems.value = false
      wishlistItemsPage.value = false
      myLists.value.forEach((element, index) => {
        if (element.id === id) {
          myLists.value[index] = response.data
        }
      })
    })
  confirmModal.value = false
}

onMounted(() => {
  const service = new WishlistServices()
  service.getList(store.user.id)
    .then((response) => {
      isLoading.value = false
      myLists.value = response.data.myLists
      otherLists.value = response.data.otherLists
      friends.value = response.data.friends
      templateItems.value = response.data.templateItems
    })
    .catch(() => {
      isLoading.value = false
    })
})
</script>

<template>
  <authenticated v-if="!wishlistItemsPage">
    <template #header>
      <span>Wish Lists</span>
      <div class="control-buttons">
        <Button action="dark" @click="newWishlist = true">
          <i class="fas fa-plus"></i> List
        </Button>
        <Button action="dark" @click="templateModal = true">
          <i class="fas fa-plus"></i> Item
        </Button>
      </div>
    </template>

    <Accordion open title="Your Lists">
      <div v-if="isLoading" class="isLoading" uk-spinner></div>
      <div class="grid-4" v-if="!store.user.preference || store.user.preference?.wishlist?.listView === false">
        <Card v-for="list in myLists" :key="list.id"
              :label="list.title"
              :infoMessage="list.status === 'draft' ? list.status : null"
              :warningMessage="list.end_date <= moment().format('YYYY-MM-DD') ? 'Expired' : null"
              :active="list.end_date > moment().format('YYYY-MM-DD')"
              @click="checkActivity(list)"
        />
      </div>
      <div v-else>
        <CardList
            v-for="list in myLists"
            :key="list.id"
            :label="list.title"
            :infoMessage="list.status === 'draft' ? list.status : null"
            :warningMessage="list.end_date <= moment().format('YYYY-MM-DD') ? 'Expired' : null"
            :date="moment(list.end_date).format('Do MMMM YYYY')"
            :active="list.end_date > moment().format('YYYY-MM-DD')"
            @click="checkActivity(list)"
        />
      </div>
    </Accordion>

    <Accordion open title="Other Lists">
      <div v-if="isLoading" class="isLoading" uk-spinner></div>
      <div class="grid-4" v-if="!store.user.preference || store.user.preference.wishlist?.singleView === false">
        <Card v-for="list in otherLists" :key="list.id"
            :label="list.title"
            @click="checkActivity(list)"
        />
      </div>
      <div v-else>
        <CardList v-for="list in otherLists" :key="list.id"
                  :label="list.title"
                  :date="moment(list.end_date).format('Do MMMM YYYY')"
                  @click="checkActivity(list)"
        />
      </div>
    </Accordion>
  </authenticated>

  <NewWishlistModal v-if="newWishlist" :friends="friends" @closeModal="newWishlist = false" @createdList="createdList" />

  <ExpiredWishlistModal v-if="expiredList"
                        :list="activeList"
                        :lists="myLists"
                        @closeModal="expiredList = false"
  />

  <WishlistItemsPage v-if="wishlistItemsPage"
                     :list="activeList"
                     :friends="friends"
                     :templateItems="templateItems"
                     @close="wishlistItemsPage = false"
                     @confirmModal="modalConfirmation"
  />

  <WishlistItemsModal v-if="wishlistItems"
                      :list="activeList"
                      :friends="friends"
                      :templateItems="templateItems"
                      @closeModal="wishlistItems = false"
                      @confirmModal="modalConfirmation"
  />

  <TemplateItemsModal v-if="templateModal" @closeModal="templateModal = false" />
  <Modal v-if="confirmModal"
         confirm
         :title="confirmationMessage"
         @closeModal="confirmModal = false"
         @confirm="confirmationMessage.includes('You won') ? activateList(activeList.id) : deleteList(activeList.id)"
  />
</template>

<style scoped>

</style>
