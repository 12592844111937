<script setup>

</script>

<template>
  <div class="widget">
    <div class="widget-header">
      <slot name="title" />
      <slot name="controls" />
    </div>
    <div class="widget-content">
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>
.widget {
  background-color: var(--tertiaryColour);
  color: white;
  border-radius: 10px;
  padding: 7px 15px;
  box-shadow: 8px 18px 18px rgba(0, 0, 0, .1);
  min-width: 253px;
}

.widget-header {
  font-size: 20px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.widget-content {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
