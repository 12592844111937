<template>
  <div class="sidebar" :class="[open === 'open' ? 'open' : 'close']">
    <div class="title">
      SOL
    </div>
    <div class="navigation-section">
      <div class="user">
        <div class="avatar">
          <img class="outline" src="../../../assets/default-avatar.png" height="65" width="65" alt="Avatar"/>
        </div>
        <div class="user-greeting" @click="router().push({name: 'Account' })">
          <div>
            Welcome,
          </div>
          {{ user.first_name }} {{ user.last_name }}
        </div>
      </div>
      <div class="nav-separate">
        <ul class="nav-list">
          <li :class="{ active: $route.name === 'Dashboard' }">
            <router-link to="/dashboard">
              <span class="icon"><i class="fas fa-home"></i> </span>
              <span class="text">Dashboard</span>
            </router-link>
          </li>
          <li :class="{ active: $route.name === 'Calendar' }">
            <router-link to="/calendar">
              <span class="icon"><i class="fas fa-calendar"></i> </span>
              <span class="text">Calendar</span>
            </router-link>
          </li>
          <li :class="{ active: $route.name === 'Notes' || $route.name === 'Note' }">
            <router-link to="/notes">
              <span class="icon"><i class="fa-solid fa-note-sticky"></i> </span>
              <span class="text">Notes</span>
            </router-link>
          </li>
          <li :class="{ active: $route.name === 'Projects' || $route.name === 'Project' }">
            <router-link to="/projects">
              <span class="icon"><i class="fas fa-project-diagram"></i> </span>
              <span class="text">Projects</span>
            </router-link>
          </li>
  <!--        <li class="uk-parent" :class="{ 'uk-open': $route.name === 'Accounts' }">
            <a href="#" v-if="open === 'close'" @click="$emit('toggleSidebar')" :class="{ active: $route.name === 'Accounts' }">
              <span class="icon"><i class="fas fa-money-bill-wave"></i></span>
              <span class="text">Finance</span>
            </a>
            <a href="#" v-if="open === 'open'" :class="{ active: $route.name === 'Accounts' }">
              <span class="icon"><i class="fas fa-money-bill-wave"></i></span>
              <span class="text">Finance</span>
            </a>
            <ul class="uk-nav-sub">
              <li :class="{ active: $route.name === 'Accounts' }">
                <router-link to="/finance-accounts">Bank Accounts</router-link>
              </li>
            </ul>
          </li>-->
          <li :class="{ active: $route.name === 'Wishlist' }">
            <router-link to="/wish-lists">
              <span class="icon"><i class="fas fa-list-ul"></i> </span>
              <span class="text">Wish List</span>
            </router-link>
          </li>
  <!--        <li :class="{ active: $route.name === 'Passwords' }">
            <router-link to="/password-manager">
              <span class="icon"><i class="fa-solid fa-eye"></i></span>
              <span class="text">Password Manager</span>
            </router-link>
          </li> -->
  <!--        <li :class="{ active: $route.name === 'Travels' || $route.name === 'Travel' }">
            <router-link to="/travels">
              <span class="icon"><i class="fa-solid fa-plane"></i></span>
              <span class="text">Travel</span>
            </router-link>
          </li>-->
          <li v-if="$route.name === 'Unauthorised'" :class="{ active: $route.name === 'Unauthorised' }">
            <router-link to="/unauthorised">
              <span class="icon"><i class="fa-solid fa-ban"></i> </span>
              <span class="text"> Unauthorised</span>
            </router-link>
          </li>
        </ul>
        <ul class="nav-list">
          <li :class="{ active: $route.name === 'Feedback' }">
            <router-link to="/feedback">
              <span class="icon"><i class="fa-solid fa-bullhorn"></i> </span>
              <span class="text">Feedback</span>
            </router-link>
          </li>
          <li :class="{ active: $route.name === 'Account' }">
            <router-link to="/account">
              <span class="icon"><i class="fa-solid fa-user"></i> </span>
              <span class="text">My Account</span>
            </router-link>
          </li>
          <li class="logout" @click="logout">
            <span class="icon"><i class="fa-solid fa-right-from-bracket"></i> </span>
            <span class="text"> Logout</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { store } from '@/store'
import router from '@/router'

export default {
  name: 'SystemSidebar',
  methods: {
    router () {
      return router
    }
  },
  props: {
    open: String,
    user: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const logout = () => {
      axios.delete('/logout?email=' + props.user.email,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('solToken')
          }
        }
      )
        .then(() => {
          localStorage.removeItem('solToken')
          localStorage.removeItem('user')
          store.isAuthenticated = localStorage.getItem('solToken')
          store.user = localStorage.getItem('user')
          router.push({ name: 'Login' })
        })
    }

    return { logout }
  }
}
</script>

<style scoped>
.title {
  font-family: 'Kanit', sans-serif;
  font-size: 35px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: .3rem;
  width: 250px;
  height: 50px;
  color: white;
  text-align: center;
  vertical-align: center;
  user-select: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100vh;
  background-color: var(--tertiaryColour);
  z-index: 5;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar.open {
  width: 250px;
  transition: 0.1s;
}

.sidebar.close {
  width: 75px;
  transition: 0.1s;
}
.sidebar.close .text {
  display: none;
}
.sidebar.close .icon {
  display: flex;
  font-size: 18px;
  justify-content: space-evenly;
  margin: 0 auto;
}
.sidebar.close .active .icon {
  margin: 0 auto;
}
.sidebar.close .avatar {
  position: absolute;
  top: 70px;
  left: -5px;
  width: 65px !important;
  height: 65px !important;
  margin-left: 10px;
}
.sidebar.close .user {
  margin: 20px 0 10px 0;
}
.sidebar.close .user-greeting {
  visibility: hidden;
}
.sidebar.close .logout {
  margin-top: 0;
  margin-bottom: 20px;
}
.sidebar.close .title {
  width: fit-content;
}

.navigation-section {
  min-height: fit-content;
  height: calc(100% - 80px);
  padding: 10px;
}

.user {
  display: flex;
  gap: 10px;
}
.user-greeting {
  margin-top: 10px;
  font-size: 14px;
  color: var(--bodyColour);
  cursor: pointer;
}
.avatar {
  width: 65px;
  height: 65px;
}
.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-list > li {
  padding: 7px 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: .2s;
}
.nav-list > li > a {
  display: flex;
  gap: 5px;
  text-decoration: none;
  color: var(--bodyColour);
  letter-spacing: 1px;
}
.nav-list > li:hover {
  background-color: var(--primaryColour);
  transition: .2s;
}

.active {
  background-color: var(--secondaryColour);
}
.active > a {
  color: white !important;
}

.nav-separate {
  margin-top: 10px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  padding: 7px 10px;
  cursor: pointer !important;
  color: var(--bodyColour) !important;
}
.logout:hover {
  background-color: var(--primaryColour);
}

</style>
